import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { AddSkillBook } from "./AddSkillBook";
import { getTabsProfile } from "./profile";
import {
  ProfileStack
} from "./Profile.styles";
import bgProfile from "../../assets/profile_bg.png";
import {
  CardProfile,
  CardRanking,
  CardUserGoals,
  CardUserProgress,
  GridMain,
  Tabs
} from "../../components";


import { WidgetDrawer } from "../../components/WidgetDrawer/WidgetDrawer.component";


import { useAuthStore } from "../../stores";

export function Profile() {
  const { t } = useTranslation();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const tabsProfileContents = getTabsProfile({
    onboarding:Boolean(corporateInfo?.onboarding),
    onboardingCapability:Boolean(corporateInfo?.onboarding_capability),
    onboardingExecutiveJob:Boolean(corporateInfo?.onboarding_executive_job),
    onboardingPersonalJob:Boolean(corporateInfo?.onboarding_personal_job)
  });

  return (
    <ProfileStack src={ bgProfile }>
      <GridMain>
        <ProfileWidgets />
        <Tabs contents={ tabsProfileContents }/>
        <AddSkillBook />
        <Box sx={ { 
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          mt: 3
        } }>
          <Typography>{ t("version-label") } { corporateInfo?.version || "0.0.0" }</Typography>
        </Box>
      </GridMain>
    </ProfileStack>
  );
}

function ProfileWidgets() {
  const theme = useTheme();
  const userData = useAuthStore(state => state.userData);
  const userInfo = useAuthStore(state => state.userInfo);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <WidgetDrawer />
      <Grid
        display={ isLarge ? "block" : "flex" }
        gap={ theme.spacing(3) }
        margin={ theme.spacing(5,0) }
        width="100%"

        sx={ {
          [theme.breakpoints.down("md")]:{
            "> div": {
              height:"auto"
            }
          },

          [theme.breakpoints.down("sm")]:{
            margin:`${theme.spacing(3)} auto 0`
          }
        } }
      >
        <Grid
          display={ isMobile ? "block" : "flex" }
          flexDirection={ "column" }
          gap={ "16px" }
          item
          xs={ 12 } sm={ 12 } md={ 12 } lg={ 7 }
          sx={ {
            [`${theme.breakpoints.down("sm")}`]:{
              maxWidth:"100%",
              minWidth:"unset",
              width:"100%"
            }
          } }
        >
          <CardProfile
            city={ userInfo?.birth_province || "" }
            email={ userData?.email || "" }
            location={ userInfo?.address || "" }
            name={ userData?.name || "" }
            phone={ userInfo?.phone_number || "" }
            surname={ userData?.surname || "" }
          />
          <Grid
            height={ "100%" }
            display={ isMedium ? "block" : "flex" }
            flexDirection={ isMobile ? "column" : "row" } gap={ 2 }

          >
            <CardUserProgress isProfilePage={ true }/>
            <CardUserGoals isProfilePage={ true }/>
          </Grid>
        </Grid>
        <Grid
          className="card-ranking"
          item
          xs={ 12 } sm={ 12 } md={ 12 } lg={ 5 }
          sx={ {
            ">div": {
              minHeight: "100%",

              ".scrollable": {
                maxHeight: "100%"
              }
            },

            [theme.breakpoints.down("sm")]: {
              width:"100%"
            }
          } }
        >
          <CardRanking isUserPage={ true }/>
        </Grid>
      </Grid>
    </>
  );
}
