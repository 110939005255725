import {
  Box,
  Grid
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  intervalToDuration
} from "date-fns";
import {
  Fragment,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  CarouselCardAnimatedStack,
  CarouselCardGrid,
  CarouselSlideBox,
  DetailTypography,
  StyledDurationBadge,
  StyledPortalWrapper,
  TitleTypography
} from "./CarouselCard.styles";
import type {
  CarouselCardBaseProps,
  CarouselCardProps
} from "./CarouselCard.types";
import {
  CardCategories,
  CardCta,
  CardSignature,
  CardType,
  CarouselCardMobile,
  Icon,
  IOpen,
  LinkWithInteractableOverlay,
  StatusBox,
  VideoCover
} from "../../components";
import { STATUS_LO } from "../../consts";
import {
  useAccent,
  useIconStatus
  // useWindowSize
} from "../../hooks";
// import { useZoomStore } from "../../stores";
import { formatDate } from "../../services/i18n-format";
import { hasPassedExpiration } from "../../utils/datetime";

export function CarouselCard({
  booked,
  categories,
  coverPublicURL,
  coverVideoPublicURL,
  courseId=undefined,
  disabled,
  duration,
  ecmRegistration,
  editionNumber,
  endDate,
  enrollType,
  expirationDate,
  grandParentId,
  id,
  isAutoSubscribeEnable,
  ecm_specialization,
  ecmType,
  isFavourite,
  isMandatory,
  isTest,
  isSurvey,
  isStandAlone,
  isToj,
  learningObjectType,
  learningObjectTypology,
  madeAttempts,
  percentageOfCompletion,
  parentId,
  rootId=undefined,
  setOpenModalEcm,
  shortDescription,
  small,
  startDate,
  status,
  title,
  ...props
} : CarouselCardProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState<IOpen>({
    label: "",
    openId: "",
    state: false
  });
  const slideBoxRef = useRef<HTMLDivElement | null>(null);
  const isExpired = hasPassedExpiration(expirationDate);
  const accentColor = useAccent({ isExpired, isMandatory, status });
  const iconStatus = useIconStatus({ status });
  const [active, setIsActive]=useState(false);

  const durationNumber = parseInt(`${duration}`);
  const interval = intervalToDuration({
    end: !isNaN(Number(duration) || 0) && duration ?  Number(duration) * 60 * 1000 : 60 * 1000,
    start: 0 }) as Duration ;

  const messageStatusBox = (isExpired && expirationDate && status !== "C" && isMandatory )
    ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
    : undefined;

  const categoryTags = categories.slice(0,2)
    .slice(0, 2)
    .concat(categories.length > 2 ? [`+${categories.length - 2}`] : []);

  return (
    <Fragment>
      <CarouselSlideBox
        className={ "" }
        $small={ small }
        $disabled={ false }
        ref={ slideBoxRef }
        onMouseOver={ ()=> setIsActive(true) }
      >
        {
          <CarouselCardGrid
            borderRadius={ undefined }
            component="section"
            container
            direction="column-reverse"
            draggable={ true }
            $coverPublicURL={ coverPublicURL }
            $noHover={ true  }
            $small={ small }
          
            onClick={ ()=> {
              isMobile && setOpenDrawer({
                label: title,
                openId: id.toString(),
                state: true
              });
            } }
            padding={
              small
                ? theme.spacing(2, 2, 2, 5)
                : theme.spacing(0, 5,3)
            
            }
            { ...props }
          >
            <Grid
              item
              container
              direction="column"
              gap={ isMobile ? 0 : 1 }
              sx={ {
                overflow:"hidden"
              } }
            >
              {
                duration && !isNaN(durationNumber) && durationNumber > 0 ?
                  <StyledDurationBadge
                    top={ isMobile ? theme.spacing(2) : theme.spacing(3) }
                    right={ small ? "1rem" : "1.5rem" }
                    maxHeight={ isMobile ? "24px" : "auto" }
                    $completed={ status === STATUS_LO.C }>
                    <Icon icon={ "Icons_time" } size={ isMobile ? 15 : 20 }/>
                    { interval.days ? t("day", { count: interval.days }) : null }
                    { interval.hours ? " " + t("hour", { count: interval.hours }) : null }
                    {  interval.minutes ? " " + interval.minutes + " " + t("min") : null }
                  </StyledDurationBadge> : null
              }
              { (status === "C" || isMandatory ) &&
                <StatusBox
                  message={ messageStatusBox }
                  accent={ accentColor }
                  icon={ iconStatus }
                />
              }
              <CardType
                ecm_specialization={ ecm_specialization }
                ecmType={ ecmType }
                fontSize={ small ? "0.875rem" :  isMobile ? "0.75rem" : "1rem" }
                isTest={  Boolean(isTest) }
                isSurvey={ Boolean(isSurvey) }
                lineHeight={ isMobile ? "0.875" : 3 / 2 }   
                learningObjectType={ learningObjectType }
                learningObjectTypology={ learningObjectTypology }
              />
              <TitleTypography
                fontSize={ (isMobile || small) ? "1.25rem" : "1.5rem" }
                lineHeight={ isMobile? "1.05" : 5 / 4 }
                $clamp={ isMobile || small ? 1 : 2 }
              >
                { title }
              </TitleTypography>
              <CardCategories
                categories={ categoryTags }
                sx={ {
                  [theme.breakpoints.down("sm")]:{
                    marginTop: "8px"
                  }
                } }
              />
              {
                !isMobile ? (
                  <LinkWithInteractableOverlay
                    $disableLink={ disabled }
                    $hideCta
                    href={ `/esplora/dettaglio/${id}/${learningObjectTypology}` }
                    tabIndex={ 0 }
                    state={ {
                      courseId:courseId,
                      rootId:rootId
                    } }
                  >
                    { title }
                  </LinkWithInteractableOverlay>
                ) : null
              }
            </Grid>
          </CarouselCardGrid>
        }
        {
          !isMobile ? (
            <StyledPortalWrapper 
              $accent={ 
                status === "C" ? 
                  theme.customColors.systemSecondary02 :  accentColor }   
              $small={ Boolean(small) }
            >
              <ZoomCard
                accentColor={ accentColor }
                booked={ booked }
                categories={ categoryTags }
                className={ "zoom-section" }
                courseId={ courseId }
                coverPublicURL={ coverPublicURL }
                coverVideoPublicURL={ coverVideoPublicURL }
                disabled={ disabled }
                duration={ duration }
                ecm_specialization={ ecm_specialization }
                ecmRegistration={ ecmRegistration }
                ecmType={ ecmType }
                editionNumber={ editionNumber }
                endDate={ endDate }
                enrollType={ enrollType }
                expirationDate={ expirationDate }
                grandParentId={ grandParentId }
                iconStatus={ iconStatus }
                id={ id }
                isActive={ active }
                isAutoSubscribeEnable={ isAutoSubscribeEnable }
                isFavourite={ isFavourite }
                isMandatory={ isMandatory }
                isStandAlone={ isStandAlone }
                isSurvey={ isSurvey }
                isTest={ isTest }
                isToj={ isToj }
                learningObjectType={ learningObjectType }
                learningObjectTypology={ learningObjectTypology }
                madeAttempts={ madeAttempts }
                messageStatusBox={ messageStatusBox }
                parentId={ parentId }
                percentageOfCompletion={ percentageOfCompletion }
                rootId={ rootId }
                setOpenModalEcm={ setOpenModalEcm }
                shortDescription={ shortDescription }
                small={ small }
                startDate={ startDate }
                status={ status }
                title={ title }
              />
            </StyledPortalWrapper>
          ) : null } 
      </CarouselSlideBox>
      {
        isMobile ? (
          <CarouselCardMobile
            booked={ booked }
            categories={ categoryTags }
            coverPublicURL={ coverPublicURL }
            coverVideoPublicURL={ coverVideoPublicURL }
            courseId={ courseId }
            disabled={ disabled }
            duration={ duration }
            ecmRegistration={ ecmRegistration }
            ecmType={ ecmType }
            editionNumber = { editionNumber }
            endDate={ endDate }
            enrollType={ enrollType }
            expirationDate={ expirationDate }
            grandParentId={ grandParentId }
            iconStatus={ iconStatus }
            isMandatory={ isMandatory }
            id={ id }
            isFavourite={ isFavourite }
            isAutoSubscribeEnable={ isAutoSubscribeEnable }
            isStandAlone={ isStandAlone }
            isSurvey={ isSurvey }
            isTest={ isTest }
            isToj={ isToj }
            ecm_specialization={ ecm_specialization }
            learningObjectType={ learningObjectType }
            learningObjectTypology={ learningObjectTypology }
            madeAttempts={ madeAttempts }
            open={ openDrawer }
            percentageOfCompletion={ percentageOfCompletion }
            parentId={ parentId }
            rootId={ rootId }
            setOpenModalEcm={ setOpenModalEcm }
            setOpen={ setOpenDrawer }
            shortDescription={ shortDescription }
            small={ small }
            startDate={ startDate }
            status={ status }
            title={ title }
          />
        ) : null
      }
    </Fragment>
  );

}

function ZoomCard({
  accentColor,
  categories,
  courseId,
  coverPublicURL,
  coverVideoPublicURL,
  disabled,
  duration,
  ecmRegistration,
  ecmType,
  editionNumber,
  endDate,
  enrollType,
  expirationDate,
  grandParentId,
  iconStatus,
  id,
  ecm_specialization,
  isMandatory,
  isStandAlone,
  isTest,
  isSurvey,
  isActive,
  isToj,
  learningObjectType,
  learningObjectTypology,
  madeAttempts,
  messageStatusBox,
  parentId,
  percentageOfCompletion,
  rootId,
  setOpenModalEcm,
  shortDescription,
  startDate,
  status,
  title
}: CarouselCardBaseProps & {
  accentColor: string
  iconStatus: ReturnType<typeof useIconStatus>
  messageStatusBox?:string
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const currentPath = window.location.href.includes("per-te") ? "per-te" : "esplora";

  const durationNumber = parseInt(`${duration}`);

  const interval = intervalToDuration({
    end: !isNaN(Number(duration) || 0) && duration ?  Number(duration) * 60 * 1000 : 60 * 1000,
    start: 0 }) as Duration ;

  return (
    <Box
      sx={ {
        display: "flex",
        height:"100%",
        justifyContent: "space-between",
        position:"relative"
  
      } }
    >
      <VideoCover
        borderRadius={ ".5rem" }
        img={  coverPublicURL }
        url={  coverVideoPublicURL }

        sx={ {
          "&:before": {
            background: `${theme.linearGradients.gradientC}`
          }
        } }
      />
      <Grid

        item
        container
        direction="column"
        gap={ 1 }
        padding="0 2.5rem 1.52125rem 2.5rem"
        zIndex={ theme.zIndex.appBar }
        justifyContent={ "flex-end" }
        position={ "relative" }
      >
        {
          duration && !isNaN(durationNumber) && durationNumber > 0 ?
            <StyledDurationBadge  
              top={ theme.spacing(3) }
              right={  "1.5rem" } 
              $completed={ status === STATUS_LO.C }>
              <Icon icon={ "Icons_time" } size={ 20 }/>
              { interval.days ? t("day", { count: interval.days }) : null }
              { interval.hours ? " " + t("hour", { count: interval.hours }) : null }
              {  interval.minutes ? " " + interval.minutes + " " + t("min") : null }
            </StyledDurationBadge> : null
        }
        { (status === "C" || isMandatory ) &&
          <StatusBox
            message={ messageStatusBox }
            accent={ accentColor }
            icon={ iconStatus }
          />
        }
        <CardType
          ecmType={ ecmType }
          ecm_specialization={ ecm_specialization }
          isTest={  Boolean(isTest) }
          isSurvey={ Boolean(isSurvey) }
          learningObjectTypology={ learningObjectTypology }
          title={ title }
          learningObjectType={ learningObjectType }
          fontSize={ "1rem" }
          lineHeight={ 3 / 2 }
        />
        <TitleTypography
          fontSize="1.5rem"
          lineHeight={ 5 / 4 }
          $clamp={ 2 }
        >
          { title }
        </TitleTypography>
        <CardCategories categories={ categories } />
        <CarouselCardAnimatedStack 
          $delay="0" 
        >
          <>
            <DetailTypography
              fontSize={ "1.25rem" }
              lineHeight={ 21/ 20 }
            >
              { shortDescription }
            </DetailTypography>
            <CardSignature
              isToj={ isToj }
              ecm_specialization={ ecm_specialization }
              startDate={ startDate }
              endDate={ endDate }
              editionNumber={ editionNumber }
              alignItems="center"
              gap={ 2 }
              expirationDate={ expirationDate }
              learningObjectTypology={ learningObjectTypology }
            />
          </>
        </CarouselCardAnimatedStack>
        <CarouselCardAnimatedStack $delay=".2"
          sx={ {
            zIndex:theme.zIndex.appBar
          } }
        >
          {
            isActive ? 
              <CardCta
                courseId={ courseId }
                disabled={ disabled }
                disableFavourites={ Boolean(isTest || isSurvey) }
                ecm_specialization={ ecm_specialization }
                ecmRegistration={ ecmRegistration }
                enrollType={ enrollType }
                grandParentId={ grandParentId }
                id={ id }
                isCard={ true }
                isStandAlone={ isStandAlone }
                isSurvey={ isSurvey }
                isTest={ isTest }
                learningObjectType={ learningObjectType }
                learningObjectTypology={ learningObjectTypology }
                madeAttempts={ madeAttempts }
                parentId={ parentId }
                percentageOfCompletion={ percentageOfCompletion }
                rootId={ rootId }
                setOpenModalEcm={ setOpenModalEcm }
                status={ status }
                title={ title }
                sx={ { 
                  justifyContent:"flex-end",
              
                  ".MuiStack-root":{
                    justifyContent:"flex-end"
                  }
                } }
              /> : null
          }
         
        </CarouselCardAnimatedStack>
        <LinkWithInteractableOverlay
          $disableLink={ disabled }
          $hideCta
          href={ `/${currentPath}/dettaglio/${id}/${learningObjectTypology}` }
          tabIndex={ 0 }
          state={ {
            courseId:courseId ?? parentId,
            rootId:rootId ?? grandParentId
          } }
        >
          { title }
        </LinkWithInteractableOverlay>
      </Grid>

    </Box>
   
  );
}
