import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TabsContentProps } from "./TabsContent.types";
import {
  ButtonLink,
  Carousel,
  CarouselCard
} from "../../components";
import {
  CAROUSEL_TYPE,
  STATUS_LO
} from "../../consts";
import { createEndDate, createStartDate } from "../../utils/general";

export function TabsContentRelated({
  contentRelated,
  currentLO
} : 
  & Required<
    Pick<
    TabsContentProps,
    | "contentRelated"
    >
  > & Pick<
  TabsContentProps,
  | "currentLO"

  >
) {
  const navigate = useNavigate();
  const minActivities  = 4;
  const showCta = (contentRelated.length > minActivities);
  const theme = useTheme();
  
  return (
    <>
      { showCta && 
        <Box 
          sx={ {
            display:"flex",
            justifyContent: "flex-end",
            width: "100%"
          } }
        >
          <ButtonLink 
            onClick={ () => navigate(`/esplora/related/${currentLO?.id}`, { state:{ 
              title: currentLO?.title,
              typeLearning: currentLO?.learningObjectType,
              typologyLearning: currentLO?.learningObjectTypology
            } 
            }) }
            sx={ {
              cursor: "pointer",
              fontSize: "1.125rem",
              fontWeight: "500",
              minWidth: "unset",
              padding: "1rem 0",
              textDecoration: "underline",
              whiteSpace: "nowrap",
              zIndex: "1000",
             
              "&:hover": {
                background:"none",
                boxShadow:"none"
              },
              [theme.breakpoints.down("sm")]: {
                fontSize:"0.75rem",
                paddingRight: "20px"
              },

              "&.Mui-disabled": {
                color:theme.customColors.backgroundDisabled,
                textDecoration:"none"
              }
            } }
          >
            Vedi tutti
          </ButtonLink>
        
        </Box> }
      <Carousel
        id={ "tabs-content-related" }
        carouselType={ CAROUSEL_TYPE.ZOOM_RELATED }
        label={ "tabs-content-related" }
      >
        {
          contentRelated?.map((card, index) => (
            <CarouselCard
              key={ `tabs-content-related-${index}` }
              enrollType={ card.enrollType }
              booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
              categories={ card.topicTags || [] }
              coverPublicURL={ card.coverPublicURL }
              coverVideoPublicURL={ card.coverVideoPublicUrl }
              disabled={ false }
              duration={ card.duration }
              ecmRegistration={ card.ecmRegistration }
              endDate={ createEndDate(card) }
              expirationDate={  card.expirationDate } //{ card.expirationDate }
              id={ card.id }
              isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
              isToj={ card.isToj }
              ecm_specialization={ card.ecm_specialization }
              isFavourite={ card.isFavourite }
              learningObjectType={ card.learningObjectType }
              learningObjectTypology={ card.learningObjectTypology }
              percentageOfCompletion={ card.percentageOfCompletion }
              shortDescription={ card.shortDescription }
              small={ true }
              startDate={ createStartDate(card) }
              status={ card.status || "N" }
              title={ card.title }
            />
          ))
        }
      </Carousel>
    </>
  );
}
