/* eslint-disable max-len */
import {
  AuthenticationDetails,
  ClientMetadata,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import { apiAdapter } from "./apiAdapter";
import { fetcherFactory } from "./fetcher";
import {
  AUTH_MODE,
  COGNITO_CHALLENGE,
  cognitoConfig
} from "../consts";
import i18n from "../services/i18n";
import type { MutableAuthState } from "../stores/authStore";
import type {
  AuthMode,
  CognitoJwtTokens,
  CognitoSeedSession,
  CorporateInfo,
  SmartConfiguration,
  UserData,
  UserInfo
} from "../types";
import {
  addTimeToCurrentDate,
  getEnvironmentVariables,
  getLastPath,
  parseJwt
} from "../utils/general";

type UserDataResponse = {
  sessionId: string
  user: UserData
}

const { basePath } = getEnvironmentVariables();

const userPool = new CognitoUserPool({
  ClientId: cognitoConfig.ClientId,
  UserPoolId: cognitoConfig.UserPoolId
});

const fetcher = fetcherFactory();

/*** SIGN IN, SIGN OUT ***/
export function signIn(username: string, password: string):
  Promise<{ session: CognitoUserSession, user: CognitoUser }>
{
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Password: password,
      Username: username
    });
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username
    });
    cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");

    cognitoUser.authenticateUser(
      authenticationDetails,
      {
        onSuccess: (session) => {
          // console.log("signIn: onSuccess", session);
          resolve({ session, user: cognitoUser });
        },

        onFailure: (error) => {
          // console.log("signIn: onFailure", error);
          reject(error);
        },

        newPasswordRequired: function(userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new password
          // and required attributes, if any, to complete authentication.
          requiredAttributes;
          // console.log("signIn: newPasswordRequired", userAttributes, requiredAttributes);

          delete userAttributes.email_verified; // the api doesn't accept this field back

          reject({
            type: COGNITO_CHALLENGE.NEW_PASSWORD_REQUIRED,
            user: cognitoUser,
            userAttributes
          });
        },

        mfaRequired: function(codeDeliveryDetails) {
          // MFA is required to complete user authentication. Get the code from user and call
          // eslint-disable-next-line no-console
          console.log("signIn: mfaRequired", codeDeliveryDetails);
        },

        totpRequired: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("signIn: totpRequired", challengName, challengeParameters);
        },

        customChallenge: (challengeParameters) => {
          // console.log("signIn: customChallenge", challengeParameters);

          reject({
            challengeParameters,
            type: COGNITO_CHALLENGE.CUSTOM_CHALLENGE,
            user: cognitoUser
          });
        },

        mfaSetup: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("signIn: mfaSetup", challengName, challengeParameters);
        },

        selectMFAType: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("signIn: selectMFAType", challengName, challengeParameters);
        }
      });
  });
}
export function signInSSO({
  baseUrl,
  clientId,
  redirectUri,
  token
} : {
  baseUrl: string,
  clientId: string,
  redirectUri: string,
  token: string,
}) {
  return fetcher(
    `${baseUrl}/oauth2/token`,
    {
      body: new URLSearchParams({
        client_id: clientId,
        code: token,
        grant_type: "authorization_code",
        redirect_uri: redirectUri
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      method: "POST"
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((data: CognitoJwtTokens) => {
      const cognitoUser = new CognitoUser({
        Pool: userPool,
        Username: parseJwt(data.id_token).identities[0].userId as string
      });
      cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
      return {
        tokens: data,
        user: cognitoUser
      };
    });
}

export async function signOut({
  authorization,
  baseUrl,
  baseUrlSSO,
  clientIdSSO,
  domain,
  deleteSession=true,
  mode,
  refreshToken,
  sessionToken,
  switchInitiative = false
} : {
  authorization: string | null
  baseUrl: string | null
  baseUrlSSO: string | null
  clientIdSSO: string | null
  deleteSession?: boolean
  domain?: string
  mode: AuthMode | null
  refreshToken: CognitoRefreshToken | null
  sessionToken: string | null
  switchInitiative?:boolean
}) {
  try {
    const cognitoUser = userPool.getCurrentUser();
    if(switchInitiative){
      document.cookie = `loadingSpinner=true;secure; expires=${addTimeToCurrentDate(5)}; domain=.${getLastPath()}; path=/`;
    }
    // remove data from browser storage
    deleteSession || cognitoUser?.signOut();

    domain && removeAdminCookie({ domain });
    localStorage.clear();
    // delete cognito and application session
    if (deleteSession) {
      try {
        const logOutCalls = [];

        // delete application session if
        if (authorization && baseUrl && sessionToken) {
          const openlearningSessionDelete = deleteApplicationSession({
            authorization,
            baseUrl,
            sessionToken
          });
          logOutCalls.push(openlearningSessionDelete);
        }

        // delete cognito session if mode is cognito
        if (mode===AUTH_MODE.Cognito && cognitoUser) {
          const cognitoSessionDelete = deleteCognitoSession({ cognitoUser });
          logOutCalls.push(cognitoSessionDelete);
        // delete cognito session if mode is sso
        } else if (
          mode===AUTH_MODE.SSO
          && authorization
          && baseUrlSSO
          && clientIdSSO
          && refreshToken
        ) {
          const ssoSessionDelete = deleteCognitoSessionSSO({
            baseUrlSSO,
            clientIdSSO,
            refreshToken
          });
          logOutCalls.push(ssoSessionDelete);
        }
        await Promise.all(logOutCalls);
      } catch(error) {
        // eslint-disable-next-line no-console
        console.error(`signOut: ${error}`);
        // console.error("signOut: could not read current user from the local userPool instance");
        return null;
      }
    }
  } catch(error) {
    // eslint-disable-next-line no-console
    console.error(`signOut: ${error}`);
    // console.error("signOut: could not read current user from the local userPool instance");
    return null;
  }
}

export async function deleteCognitoSession({
  cognitoUser
} : {
  cognitoUser: CognitoUser
}) {
  return new Promise<void>((resolve) => {
    cognitoUser?.getSession((error: Error | null, session: CognitoUserSession | null) => {
      if (session) {
        cognitoUser?.globalSignOut({
          onFailure: (err: Error) => {
            // eslint-disable-next-line no-console
            console.error("Could not revoke cognito session", err);
            resolve();
          },
          onSuccess: (msg: string) => {
            // eslint-disable-next-line no-console
            console.log(msg);
            resolve();
          }
        });
      } else if (error) {
        // eslint-disable-next-line no-console
        console.error("Could not find session during sign out", error);
        resolve();
      }
    });
  });
}
export function deleteCognitoSessionSSO({
  baseUrlSSO,
  clientIdSSO,
  refreshToken
} : {
  baseUrlSSO: string
  clientIdSSO: string
  refreshToken: CognitoRefreshToken
}) {
  return fetch(
    `${baseUrlSSO}/oauth2/revoke`,
    {
      body: new URLSearchParams({
        client_id: clientIdSSO,
        grant_type: "refresh_token",
        token: refreshToken.getToken()
      }),
      headers: {
        // "Authorization": authorization,
        "Content-Type": "application/x-www-form-urlencoded"
        // "x-ada-session-token": sessionToken
      },
      method: "POST"
    }
  );
}

export async function deleteApplicationSession({
  authorization,
  baseUrl,
  sessionToken
} : {
  authorization: string
  baseUrl: string
  sessionToken: string
}) {
  return fetch(
    `${baseUrl}/sessions/${sessionToken}`,
    {
      headers: {
        "Authorization": authorization,
        "x-ada-session-token": sessionToken
      },
      method: "DELETE"
    }
  );
}

/*** REFRESH ***/
export function seedSession({
  baseUrl,
  sessionToken
} : {
  baseUrl: string,
  sessionToken: string
}) {
  return fetcher(
    `${baseUrl}/cognito/${sessionToken}`,
    {
      headers: {
        "x-ada-session-token": sessionToken
      }
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((data: CognitoSeedSession) => {
      // const userPool = new CognitoUserPool({
      //   ClientId: data.clientId,
      //   UserPoolId: data.userPoolId
      // });
      // const cognitoUser = new CognitoUser({
      //   Pool: userPool,
      //   Username: parseJwt(data.idToken)["cognito:username"]
      // });
      // cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
      // return { user: cognitoUser, ...data };
      return { userPool, ...data };
    });
}

export function refreshSessionSSO({
  baseUrl,
  clientId,
  refreshToken
} : {
  baseUrl: string
  clientId: string,
  refreshToken: CognitoRefreshToken
}): Promise<Omit<CognitoJwtTokens, "refreshToken">> {
  return fetch(
    `${baseUrl}/oauth2/token`,
    {
      body: new URLSearchParams({
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken.getToken()
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      method: "POST"
    }
  )
    .then((res) => {
      if (!res.ok) {
        res.text().then((response) => {
          throw(response);
        });
      } else {
        return res.json();
      }
    });
}

export async function refreshSessionCognito({
  refreshToken
} : {
  refreshToken: CognitoRefreshToken
}): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser?.refreshSession(
        refreshToken,
        (error, session) => {
          if (error) {
            // console.error("service: refreshSessionCognito", error);
            reject(error);
          } else if (session) {
            resolve(session);
          } else {
            // console.error("refresh cognito session: unknown error");
            reject(new Error("refresh cognito session: unknown error"));
          }
        }
      );
    } else {
      reject("the user pool has no user session for this id");
    }
  });
}

/*** API OPEN LEARNING ***/
export async function getApiSessionToken({
  accessToken,
  authorization,
  contentType="application/json",
  method="POST",
  refreshToken,
  url=`${basePath}/sessions`
} : {
  authorization: string
  accessToken: string
  contentType?: string
  method?: string
  refreshToken: string
  url?: string
}) : Promise<string>{
  return new Promise((resolve, reject)=> {
    fetcher(
      url,
      {
        headers: {
          "Authorization": authorization,
          "Content-type": contentType,

          "AuthToken": accessToken,
          "RefreshToken": refreshToken
        },
        method: method
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.errors) {
          reject("/session: could not fetch session id");
        } else if (!data.sessionId || typeof data.sessionId !== "string") {
          reject("/session: could not fetch session id");
        } else {
          resolve(data.sessionId); // this is our sessionToken
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
// CACHABLE?
export async function getApiUserInfo({
  accessToken,
  sessionToken
} : {
  accessToken:string,
  sessionToken: string
}): Promise<UserInfo> {
  return new Promise((resolve, reject) => {
    fetcher(
      `${basePath}/usermgmt/users/self`,
      {
        headers: {
          "Authorization":accessToken ?? "",
          "x-ada-session-token": sessionToken ?? ""
        }
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: UserInfo) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// CACHABLE?
export async function getApiUserData({
  accessToken,
  contentType="application/json",
  method="GET",
  sessionToken
} : {
  accessToken:string,
  contentType?: string,
  method?: string,
  sessionToken: string | null,
}) : Promise<UserData> {
  return new Promise((resolve, reject) => {
    fetcher(
      `${basePath}/sessions/${sessionToken}`,
      {
        headers: {
          "Authorization":accessToken,
          "Content-type": contentType
        },
        method: method
      })
      .then((res) => {
        return res.json();
      })
      .then((data: UserDataResponse & { errors: unknown[] }) => {
        if (Array.isArray(data.errors) && data.errors.length > 0) {
          throw new Error(`getApiUserData: ${JSON.stringify(data.errors[0])}`);
        } else if (!data.user) {
          throw new Error("getApiUserData: couldn't fetch the user data");
        } else {
          resolve(data.user);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export async function getApiCorporateInfo({
  accessToken,
  corporateId,
  sessionToken
} : {
  accessToken: string
  corporateId: number
  sessionToken: string,
}) : Promise<CorporateInfo> {
  return new Promise((resolve, reject) => {
    fetcher(
      `${basePath}/usermgmt/corporates/${corporateId}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
// CACHABLE Iniziativa
export async function getSmartConfiguration({
  accessToken,
  corporateId,
  initiativeId,
  sessionToken
} : {
  accessToken: string,
  corporateId: number,
  initiativeId: number,
  sessionToken: string,
}): Promise<SmartConfiguration> {
  return new Promise((resolve, reject) => {
    fetcher(
      `${basePath}/smartconfigurator/configurations/${corporateId}/${initiativeId}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: SmartConfiguration) => {
        resolve(apiAdapter([data])[0] as SmartConfiguration);
      })
      .catch(error => {
        reject(error);
      });
  });
}
// export function getUserInfo({
//   accessToken,
//   baseUrl
// } : {
//   accessToken: string
//   baseUrl: string,
// }) {
//   return fetcher(
//     `${baseUrl}/oauth2/userInfo`,
//     {
//       headers: {
//         Authorization: `Bearer ${accessToken}`
//       }
//     }
//   );
// }
export async function setApiOnBoardingCompleted({
  sessionToken,
  authorization
} : {
  sessionToken: string,
  authorization: string
}): Promise<void> {
  return new Promise((resolve, reject)=> {
    fetcher(
      `${basePath}/usermgmt/users/self/onboarding`,
      {
        headers: {
          "Authorization": authorization,
          "Content-type": "application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PUT"
      }
    )
      .then(() => { resolve() })
      .catch(error => { reject(error) });
  });
}

export async function setApiSessionInitiative({
  accessToken,
  authorization,
  initiativeId,
  refreshToken,
  sessionToken
} : {
  accessToken: string
  authorization: string
  initiativeId: string
  refreshToken: string
  sessionToken: string
}) : Promise<string> {
  return new Promise((resolve, reject)=> {
    fetcher(
      `${basePath}/sessions`,
      {
        body: JSON.stringify({
          initiativeId,
          sessionId: sessionToken
        }),
        headers: {
          "Authorization": authorization,
          "Content-type": "application/json",

          "AuthToken": accessToken,
          "RefreshToken": refreshToken
        },
        method: "POST"
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        resolve(data.sessionId);
      })
      .catch(error => { reject(error) });
  });
}
export async function setApiPrivacyAgreements({
  accessToken,
  sessionToken
} : {
  accessToken:string,
  sessionToken: string
}): Promise<void> {
  return new Promise((resolve, reject)=> {
    fetcher(
      `${basePath}/usermgmt/users/self/privacyconsent`,
      {
        headers: {
          "authorization":accessToken,
          "x-ada-session-token": sessionToken ?? ""
        },
        method: "PUT"
      }
    )
      .then(() => { resolve() })
      .catch(error => {
        reject(error);
      });
  });
}

/*** COGNITO CHECKS ***/
/**
 * cognitoTokenIsValid
 * checks if the iat of the access token is past the current time
 * NOTE: this isn't strictly necessary for refresh with cognito library
 * cfr. https://stackoverflow.com/a/71795288
 * it is used so the control flow of the library vs sso cases stays similar
 * @param shift a shift of the current time, in milliseconds
 * @returns a boolean indicating if the tokens are valid or not
 */
/* the pattern is to send a refetch each time an api returns a 401, the acess token iat is irrelevant */
// export function cognitoTokenIsValid(shift = 0) {
//   const user = localStorage.getItem(
//     `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.LastAuthUser`
//   );
//   if (!user) { return false }
//   const accessToken = localStorage.getItem(
//     `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.${user}.accessToken`
//   );
//   if (!accessToken) { return false }
//   const expiration = parseJwt(accessToken).exp;
//   if (!expiration) {
//     return false;
//   } else if (Date.now() + shift - (expiration * 1000) >= 0) {
//     return false;
//   } else {
//     return true;
//   }
// }
/* replaced in auth store */
// export function getCurrentUser() {
//   const currentUser = userPool.getCurrentUser();
//   if (!currentUser) throw new Error("amazon-cognito-identity-js: cognito couldn't fetch the current user");
//   return currentUser;
// }

/*** STORAGE ***/
function calculateClockDrift(accessToken: string, idToken: string) {
  const now = Math.floor(Date.now() / 1000);
  const accessTokenIat = parseJwt(accessToken).iat as number;
  const idTokenIat = parseJwt(idToken).iat as number;
  const iat = Math.min(accessTokenIat, idTokenIat);
  return (now - iat).toString();
}
export function persistAuthStore(store: MutableAuthState) {
  if (store.mode && store.session && store.sessionToken && store.user && store.userData) {
    // localStorage.setItem("authState", btoa(JSON.stringify({ ...store, navigateTo: null })));
    localStorage.setItem("authState", JSON.stringify({ ...store, navigateTo: null }));
  }
}
export function recoverAuthStore(): MutableAuthState | null {
  const serializedAuthState = localStorage.getItem("authState");
  if (!serializedAuthState) return null;
  // const storedStore = JSON.parse(atob(serializedAuthState));
  const storedStore = JSON.parse(serializedAuthState);
  storedStore.navigateTo = null;
  const newSession = new CognitoUserSession({
    AccessToken: new CognitoAccessToken({ AccessToken: storedStore.session.accessToken.jwtToken }),
    IdToken: new CognitoIdToken({ IdToken: storedStore.session.idToken.jwtToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: storedStore.session.refreshToken.token })
  });
  const newUser = new CognitoUser({
    Pool: userPool,
    Username: storedStore.user
  });
  newUser.setAuthenticationFlowType("CUSTOM_AUTH");
  newUser.setSignInUserSession(newSession);
  setAdminCookie({
    accessToken:newSession.getAccessToken().getJwtToken(),
    authorization: newSession.getIdToken().getJwtToken(),
    domain: storedStore.corporateInfo?.corporate_domain,
    sessionId: storedStore.sessionToken
  });
  return {
    ...storedStore,
    session: newSession,
    user: newUser
  };
}
export function removeAdminCookie({
  domain
} : {
  domain: string
}) {
  document.cookie = `digitedUser=;secure; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${domain}; path=/`;
}
export function setAdminCookie({
  accessToken,
  authorization,
  domain,
  sessionId
} : {
  accessToken: string
  authorization: string
  domain: string
  sessionId: string
}) {
  const cookieUser = {
    accessToken:accessToken,
    authToken: authorization,
    sessionId: sessionId // this is our sessionToken
  };
  document.cookie = `digitedUser=${JSON.stringify(cookieUser)};secure; domain=.${domain}; path=/`;
}
export function storeJwtTokens(tokens: CognitoJwtTokens) {
  const idToken = parseJwt(tokens.id_token);
  const userId = idToken.identities[0].userId as string;
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.${userId}.accessToken`,
    tokens.access_token
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.${userId}.idToken`,
    tokens.id_token
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.${userId}.refreshToken`,
    tokens.refresh_token
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.${userId}.clockDrift`,
    calculateClockDrift(tokens.access_token, tokens.id_token)
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${cognitoConfig.ClientId}.LastAuthUser`,
    userId
  );
}

/*** PASSWORD ***/
export function changePassword(
  cognitoUser: CognitoUser,
  oldPassword: string,
  newPassword: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(
      oldPassword,
      newPassword,
      (error, status) => {
        if (error) {
          // console.error("service: changePassword", error);
          reject(error);
        } else if (status === "SUCCESS") {
          resolve();
        } else {
          reject(`changePassword: ${status}`);
        }
      }
    );
  });
}
export function confirmPassword(
  username: string,
  newPassword: string,
  mfaCode: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username
    });
    cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");
    cognitoUser.confirmPassword(mfaCode, newPassword, {
      onSuccess: () => {
        resolve();
      },

      onFailure: (err) => {
        // console.error("service: confirmPassword", err);
        reject(err);
      }
    });
  });
}
export function forgotPassword(username: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username
    });
    cognitoUser.setAuthenticationFlowType("CUSTOM_AUTH");

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        data;
        // console.log("forgotPassword: onSuccess", data);
        resolve();
      },

      onFailure: (err) => {
        // console.log("forgotPassword: onFailure", err);
        reject(err);
      },

      inputVerificationCode: (data) => {
        data;
        // console.log("forgotPassword: inputVerificationCode", data);
        // var code = document.getElementById('code').value;
        // var newPassword = document.getElementById('new_password').value;
        // cognitoUser.confirmPassword(verificationCode, newPassword, {
        //   onSuccess() {
        //     console.log('Password confirmed!');
        //   },
        //   onFailure(err) {
        //     console.log('Password not confirmed!');
        //   },
        // });
        resolve();
      }
    });
  });
}
export function completeNewPasswordChallenge({
  cognitoUser,
  sessionUserAttributes,
  newPassword
} : {
  cognitoUser: CognitoUser,
  sessionUserAttributes: CognitoUserAttribute,
  newPassword: string
// }): Promise<CognitoUserSession> {
}): Promise<void> {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      sessionUserAttributes,
      {
        onSuccess: (session, userConfirmationNecessary) => {
          userConfirmationNecessary;
          // console.log("completeNewPasswordChallenge: onSuccess", session, userConfirmationNecessary);
          // resolve(session);
          resolve();
        },

        onFailure: (err) => {
          // console.log("completeNewPasswordChallenge: onFailure", err);
          reject(err);
        },

        newPasswordRequired: function(userAttributes, requiredAttributes) {
          // eslint-disable-next-line no-console
          console.log("completeNewPasswordChallenge: newPasswordRequired", userAttributes, requiredAttributes);
        },

        mfaRequired: function(codeDeliveryDetails) {
          // eslint-disable-next-line no-console
          console.log("completeNewPasswordChallenge: mfaRequired", codeDeliveryDetails);
        },

        totpRequired: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeNewPasswordChallenge: totpRequired", challengName, challengeParameters);
        },

        customChallenge: (challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeNewPasswordChallenge: customChallenge", challengeParameters);
        },

        mfaSetup: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeNewPasswordChallenge: mfaSetup", challengName, challengeParameters);
        },

        selectMFAType: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeNewPasswordChallenge: selectMFAType", challengName, challengeParameters);
        }
      }
    );
  });
}
export function completeCustomChallenge({
  challengeResponse,
  clientMetaData,
  cognitoUser
} : {
  challengeResponse: string
  clientMetaData:ClientMetadata
  cognitoUser: CognitoUser
}): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    cognitoUser.sendCustomChallengeAnswer(
      challengeResponse,
      {
        onSuccess: (session, userConfirmationNecessary) => {
          userConfirmationNecessary;
          // console.log("completeCustomChallenge: onSuccess", session, userConfirmationNecessary);
          resolve(session);
        },

        onFailure: (err) => {
          // console.log("completeCustomChallenge: onFailure", customError);
          if (err.message === "Incorrect username or password.") {
            reject(new Error(i18n.t("err_msg_mfa_too_many_attempts")));
          } else if (err.message === "Invalid session for the user.") {
            reject(new Error(i18n.t("err_msg_mfa_code_expired")));
          } else {
            reject(err);
          }
        },

        newPasswordRequired: function(userAttributes, requiredAttributes) {
          // eslint-disable-next-line no-console
          console.log("completeCustomChallenge: newPasswordRequired", userAttributes, requiredAttributes);
        },

        mfaRequired: function(codeDeliveryDetails) {
          // eslint-disable-next-line no-console
          console.log("completeCustomChallenge: mfaRequired", codeDeliveryDetails);
        },

        totpRequired: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeCustomChallenge: totpRequired", challengName, challengeParameters);
        },

        customChallenge: (challengeParameters) => {
          // console.log("completeCustomChallenge: customChallenge", challengeParameters);

          reject({
            challengeParameters,
            type: COGNITO_CHALLENGE.CUSTOM_CHALLENGE
          });
        },

        mfaSetup: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeCustomChallenge: mfaSetup", challengName, challengeParameters);
        },

        selectMFAType: (challengName, challengeParameters) => {
          // eslint-disable-next-line no-console
          console.log("completeCustomChallenge: selectMFAType", challengName, challengeParameters);
        }
      },
      clientMetaData
    );
  });
}

export async function retrieveExternalIdByUsername({ corporateId, username }: { corporateId: number, username: string}) {
  return fetcher(
    `${basePath}/sessions/user/externalId?corporateId=${corporateId}&username=${username}`,
    {
      headers: {
        "Content-Type": "application/json"
      },
      method: "GET"
    }
  ).then((res) => {
    return res.json();
  })
    .catch((error) => {
      console.log("--- Error retrieveExternalIdByUsername", error);
      throw error;
    });
}
