import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  DetailTypography,
  HeroBannerStack,
  StyledBadgeHero,
  TitleTypography
} from "./HeroBanner.styles";
import { HeroBannerProps } from "./HeroBanner.types";
import {
  ButtonLink,
  CardCategories,
  CardCta,
  CardSignature,
  CtaOverlay,
  Icon,
  StatusBox,
  VideoCover
} from "../../components";
import {
  LEVEL, TAB_ITEMS
} from "../../consts";
import { useAccent, useIconStatus, useType } from "../../hooks";
import { formatDate } from "../../services";
import { useNavbarStore } from "../../stores";
import { hasPassedExpiration } from "../../utils/datetime";


// import { CardSignature } from "../CardSignature";

export function HeroBanner({
  badge,
  certificate,
  booked,
  categories,
  coverPublicURL,
  coverVideoPublicUrl,
  courseId=undefined,
  duration,
  ecmRegistration,
  ecmType,
  editionEndSubscriptionDate,
  editionNumber,
  editionStartSubscriptionDate,
  editionVirtualLink,
  enrollType,
  expirationDate,
  id,
  ecm_specialization,
  isFavourite,
  isMandatory,
  isStandAlone,
  isSurvey,
  isTest,
  isToj,
  learningObjectType,
  learningObjectTypology,
  meetingDetails,
  percentageOfCompletion,
  rating,
  rootId=undefined,
  setOpenTojModal,
  shortDescription,
  startDate,
  endDate,
  setOpenModalEcm,
  status,
  title,
  ...props
}: HeroBannerProps) {

  const { t, i18n } = useTranslation();
  const actualDay = new Date().getTime();
  const { state:routerState }=useLocation();
  const isActualDay =
  editionEndSubscriptionDate && editionStartSubscriptionDate &&
    (actualDay >= new Date(editionStartSubscriptionDate).getTime() -  (2*60*60*1000))
    && (actualDay <= new Date(editionEndSubscriptionDate).getTime());

  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);
  const isExpired = hasPassedExpiration(expirationDate);
  const iconStatus = useIconStatus({ status });
  const accent = useAccent({ isExpired, isMandatory, status });
  const [videoVisible, setVideoVisible] = useState(Boolean(coverVideoPublicUrl));
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  const cardCtaVisibility = (() => {
    switch (learningObjectTypology) {
    case LEVEL.DA_PHYSICAL_CLASS:
      return {
        disabled: false,
        visibility: true
      };
    case LEVEL.DA_VIRTUAL_CLASS:
      return {
        disabled: booked ? !isActualDay : false,
        visibility: true
      };
    case LEVEL.DA_MEETING:
      return {
        disabled:true,
        visibility: false
      };
    default:
      return {
        disabled: isTest && status === "C" || false,
        visibility: true
      };
    }
  })();
  const navigate = useNavigate();
  const theme = useTheme();
  const messageStatusBox = (isExpired && expirationDate && status !== "C" && isMandatory )
    ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
    : undefined;

  return (

    <Box
      sx={ {
        background:!videoVisible
          ? `${theme.linearGradients.gradientC}, url(${coverPublicURL})`
          : theme.linearGradients.gradientC,
        backgroundPosition: "center",
        backgroundRepeat:"no-repeat",
        backgroundSize: "cover",
        position:"relative"
      } }
    >
      {
        videoVisible && coverVideoPublicUrl ?
          <VideoCover
            sx={ {
              "&:before": {
                background: `${theme.linearGradients.gradientC}`
              }
            } }
            img={ coverPublicURL.toString() }
            onEnded={ ()=> { setVideoVisible(false)} }
            url={ coverVideoPublicUrl }
          /> : null
      }


      <HeroBannerStack
        component="section"
        gap={ 2 }
        position={ "relative" }
        { ...props }
      >
        {
          ( status === "C" || isMandatory) ?
            (
              <StatusBox
                accent={ accent }
                icon={ iconStatus }
                message={ messageStatusBox }
                inHeroBanner={ true }
                sx={ {
                  height:"60px",
                  width: isExpired ? "fit-content" : "60px",

                  left:0,
                  padding:isExpired ? "0 16px" : 0,
                  top:"80px",

                  "svg":{
                    height:"30px !important",
                    width:"30px !important"
                  }
                } }
              />
            ) : null
        }

        { (
          booked && (
            learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
              || learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
              || isToj
          ) ?
            <StyledBadgeHero
              sx={ {
                borderRadius: (status === "C" || isMandatory ) ? "0.25rem" :  "0 0.25rem 0.25rem 0",
                left:(status === "C" || isMandatory ) ? "68px" : "0",
                top:"80px" 
              } }
            >
              { t("registered") }
            </StyledBadgeHero>
            : null
        )
        }
        <ButtonLink
          onClick={ () =>  {
            if(routerState?.goBackPath){
              navigate(routerState.goBackPath);
            }else if(routerState?.isFromCommunity){
              navigate(routerState.isFromCommunity);
              setNavbarItem("COMMUNITY");
            }
            else {
              navigate(-1);
            }} }
          disableRipple
          className={ "button-back" }
        >
          <Icon color={ theme.customColors.systemPrimary01 } className={ "icon" } icon={ "arrow_left" }/>
          { t("back") }
        </ButtonLink>

        {
          isTest ?

            <span className={ "type" } style={ { color: theme.customColors.borderAccent } }>
              {   t("test") }
            </span>
            :

            isSurvey ?
              <span className={ "type" } style={ { color: theme.customColors.borderAccent } }>
                {   t("survey") }
              </span> :
              null
        }
        {
          ecm_specialization && ecmType ? 

            <span className={ "type" } style={ { color: theme.customColors.borderAccent } }>
              { ecmType.toUpperCase() }
            </span> : 
            typeObjectLearning && !isTest && !isSurvey  ?
              <span className={ "type" } style={ { color: theme.customColors.borderAccent } }>
                { typeObjectLearning }
              </span>
              : null
        }
        <TitleTypography variant="h1">
          { title }
        </TitleTypography>
        {
          categories?.length > 0 ?
            <CardCategories categories={ categories }/> :
            null
        }
        <DetailTypography>
          { shortDescription }
        </DetailTypography>
        <CardSignature
          ecm_specialization={ ecm_specialization }
          editionNumber={ editionNumber }
          expirationDate={ expirationDate }
          duration={ duration }
          startDate={ startDate }
          endDate={ endDate }
          gap={ 2 }
          isToj={ isToj }
          learningObjectTypology={ learningObjectTypology }
          meetingDetails={ meetingDetails }
        />
        {
          cardCtaVisibility.visibility ?
            <CardCta
              disableFavourites={ Boolean(isTest || isSurvey) }
              badge={ badge }
              certificate={ certificate }
              disabled={ cardCtaVisibility.disabled }
              ecmRegistration={ ecmRegistration }
              enrollType={ enrollType }
              className={ "card-cta" }
              courseId={ courseId }
              id={ id }
              ecm_specialization={ ecm_specialization }
              maxWidth={ "600px" }
              isStandAlone={ isStandAlone }
              learningObjectTypology={ learningObjectTypology }
              learningObjectType={ learningObjectType }
              linkVirtualClass={ editionVirtualLink }
              percentageOfCompletion={ percentageOfCompletion }
              isTest={ isTest }
              isSurvey={ isSurvey }
              rootId={ rootId }
              simple={ false }
              status={ status }
              triggerOpenTabs={ openEditions }
              isCard={ false }
              setOpenRegistrationModal={ setOpenModalEcm }
              setOpenTojModal={ setOpenTojModal }
            /> :
            null
        }

        <CtaOverlay
          isStandAlone={ isStandAlone }
          isFavourite={ isFavourite }
          id={ id }
          learningObjectTypology={ learningObjectTypology }
          rating={ rating }
          share={ true }
          courseId={ courseId }
          rootId={ rootId }
        />
      </HeroBannerStack>

    </Box>
  );

  function openEditions() {
    const tabsComponent = document.querySelector(".MuiTabs-flexContainer") as HTMLDivElement;

    if (tabsComponent) {
      const tabs = tabsComponent.querySelectorAll("button") as NodeListOf<HTMLButtonElement>;
      const labelToCheck =
        learningObjectTypology === LEVEL.COURSE ? TAB_ITEMS.ACTIVITIES
          : learningObjectTypology === LEVEL.PATH ? "modules"
            : TAB_ITEMS.EDITIONS;
      if (labelToCheck !== "modules"){
        tabs.forEach((tab) => {
          if (tab.id.includes(labelToCheck)) {
            tab.click();
            tab.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }
        });
      }
      else {
        const modules = document.querySelector(".modules-tab");
        if(modules){
          modules.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
      }


    }
  }
}

