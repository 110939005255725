import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useLocation, useNavigate } from "react-router-dom";
import logoFallback from "../../assets/digited_logo.png";
import logoMobileFallback from "../../assets/logoMobile.png";
import { GuardedLink, Icon, Logo } from "../../components";
import { getEnvironmentVariables } from "../../utils/general";


export const Maintenance = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    state = { isGlobalMaintenance: true }
  } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate= useNavigate();

  const { digitedLogo, digidetLogoMobile, digitedLogoHeight } = getEnvironmentVariables();

  return <div style={ { height: "100vh", display: "flex", flexDirection: "column" } }>
    <header style={ { padding: "20px" } }>
      <Logo
        alt="logo"
        width="auto"
        // eslint-disable-next-line max-len
        height={ digitedLogoHeight ? digitedLogoHeight + "px" : "32px" }
        src={ isMobile ? digidetLogoMobile ?? logoMobileFallback : digitedLogo ?? logoFallback }
      />
    </header>
    <section style={ { 
      alignItems: "center",
      display: "flex", 
      flex: "1 0 auto", 
      flexDirection: "column", 
      justifyContent: "center" 
    } }>
      { !state.isGlobalMaintenance && <div style={ { width: "100%" } }>
        <GuardedLink
          alignSelf="flex-start"
          color="info"
          onClick={ () => navigate("/access/portal", { state: { maintenancePage: true } }) }
          href={ "/" }
          startIcon={
            <Icon
              icon="arrow_left"
              color={ theme.customColors.systemPrimary01 }
              size={ isMobile ? 15 : 20 }
            />
          }
          variant="text"
          sx={ {
            fontSize: (isMobile ? "0.75rem" : "1.125rem"),
            fontWeight: 400,
            lineHeight: isMobile ? (11 / 6) : (11 / 9),
            minWidth: isMobile ? "auto" : "128px",
            padding: isMobile ? "16px 0 0 0" : undefined
          } }
        >
          { t("back") }
        </GuardedLink>
      </div>
      }
      <div style={ { flex: "1 0 auto", width: "100%", alignContent: "center" } }>
        <Typography
          fontSize={ "2rem" }
          fontWeight={ 700 }
          color={ theme.customColors.textPrimaryCta }
          textAlign={ "center" }
          sx={ { marginBottom: "20px" } }
        > 
          { t("maintenance-title") }
        </Typography>
        <Typography
          fontSize={ "1rem" }
          fontWeight={ 700 }
          color={ theme.customColors.textPrimary }
          textAlign={ "center" }
        > 
          { t("maintenance-description") }
        </Typography>
      </div>
    </section>
  </div>;
};
