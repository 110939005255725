import { RegisterGlobalStateInterface } from "../components/Register/types";
import { Field } from "queries/autoreg";

export const isEmail = (value: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

export function isPhoneNumberValid(phoneNumber: string): boolean {
  const phoneRegex = /^[+\d]?(?:[\d-.\s()]*)$/;
  return phoneRegex.test(phoneNumber);
}

export const getOptionsFieldWithDependencies = (field: Field, state: RegisterGlobalStateInterface) => {
  if (field.depends_on) {
    const allDependenciesPopulated = field.depends_on.every((k: string) => state[k] && state[k] !== "");
    if (allDependenciesPopulated) { 
      return { enabled: true, options:[] };
    }
    return { enabled: false, options: [] };
  }
  return { enabled: true, options: field.options || []  };
};


