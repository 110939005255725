import {
  useQuery,
  type QueryKey
} from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { fetcherFactory } from "../services";
import { useAuthStore } from "../stores";
import type {
  Language,
  Translations
} from "../types";
import { getEnvironmentVariables } from "../utils/general";

const { basePath, corporateLanguage } = getEnvironmentVariables();

type LanguagesResponse = {
  id: number | null
  enable_multilanguage: boolean
  primary_language: Language
  secondary_languages: Language[]
}

export function useGetLanguages({
  enabled = true,
  queryKey = []
}: {
  enabled?: boolean
  queryKey?: string[]
}) {
  const fetcher = fetcherFactory();
  const userData = useAuthStore(state => state.userData);
  const corporateId = userData?.organization_id ?? corporateLanguage;

  // eslint-disable-next-line max-len
  //Nella pagina di Login la lingua di default settata a client non corrisponde con il valore di primary_language restituito dall’api. 
  // eslint-disable-next-line max-len
  //Inoltre, il valore di primary_language è diverso da quello ottenuto una volta loggati. Questo perchè la variabile d'ambiente "corporateLanguage" potrebbe non avere un id di successo.  
  const uri = `${basePath}/usermgmt/corporates/${corporateId}/languages`;

  return useQuery<
    unknown,
    unknown,
    {
      enable_multilanguage: boolean,
      primary_language: Language,
      languages: Language[]
    },
    QueryKey
  >({
    enabled: enabled && Boolean(corporateId),
    queryFn: () => fetcher(
      uri,
      {
        headers: {
          "accept": "application/json"
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: LanguagesResponse) => {
        const languages =
          (data.primary_language ? [data.primary_language] : [])
            .concat(data.secondary_languages);

        return {
          enable_multilanguage: data.enable_multilanguage ?? false,
          languages
        };
      }),
    queryKey: [uri, ...queryKey],
    refetchOnMount: false
  });
}

export function useGetTranslations({
  enabled = true,
  queryKey = [],
  language
}: {
  enabled?: boolean
  queryKey?: string[]
  language: string
}) {
  const { i18n } = useTranslation();
  const fetcher = fetcherFactory();
  const userData = useAuthStore(state => state.userData);

  const corporateId = userData?.organization_id ?? corporateLanguage;
  const initiativeId = userData?.initiative_id ?? "";

  const uri = `${basePath}/smartconfigurator/labelTranslations/WEB/${corporateId}`;
  const params = `initiativeId=${initiativeId}&langCode=${language}`;
  const { data: multiLanguageData } = useGetLanguages({});
  const primary_language = multiLanguageData?.languages[0].id;
  const htmlTag = document.querySelector("html");

  return useQuery<
    unknown,
    unknown,
    Translations,
    QueryKey
  >({
    enabled: enabled && Boolean(
      corporateId
      && language
      && primary_language
    ),
    onSuccess: (data) => {
      if (data.translation[language]) {
        i18n.addResourceBundle(
          language,
          "translation",
          { ...data.translation[language] }
        );
        i18n.changeLanguage(language);
        htmlTag?.setAttribute("lang", language);
      } else if (primary_language) {
        i18n.addResourceBundle(
          primary_language,
          "translation",
          { ...data.translation[primary_language] }
        );
        i18n.changeLanguage(primary_language);
        htmlTag?.setAttribute("lang", primary_language);
      }

    },
    queryFn: () => fetcher(
      `${uri}?${params}`,
      {
        headers: {
          "accept": "application/json"
        }
      })
      .then((res) => {
        return res.json();
      }),
    queryKey: [uri, params, ...queryKey],
    refetchOnMount: false
  });
}


