import {
  Grid,
  Typography,
  TypographyProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";

export const CardGrid = styled(Grid)<{$booked:boolean}>(({ $booked, theme }) => ({
  background: theme.linearGradients.gradientB,
  border: $booked  ? `1px solid ${theme.customColors.accentVariantA}` : 0,
  borderRadius: "8px",
  flexDirection:"row",
  flexWrap:"nowrap",
  justifyContent:"space-between",
  margin:" 0 0 24px",
  maxWidth: "550px",
  padding: "0.95rem 2rem",
  position:"relative",


  ".separator": {
    alignSelf: "stretch",
    background: theme.customColors.border,
    margin:"14px 0",

    height:"auto",
    maxWidth:"1px",
    width:"10%",

    [theme.breakpoints.down("sm")]:{
      margin:"8px 0"
    }
  },

  [theme.breakpoints.down("sm")]:{
    alignItems: "center",
    maxWidth: "unset",
    padding: theme.spacing(1, 0),
    width:"100%"
  }
}));

export const CardBodyGrid = styled(Grid)<{$booked:boolean, $currentDay:boolean}>(({ theme, $booked,$currentDay }) => ({
  flexDirection: "column",

  "&:first-of-type": {
    "svg": {
      minHeight: "20px",
      minWidth: "20px"
    },
    
    ".icon path": {
      fill: theme.customColors.systemPrimary01
    }
  },

  ".location":{
    color: $booked && $currentDay ? theme.palette.primary?.main : theme.customColors.textPrimary,
    "p":{
      margin:0
    },

    ".icon path":{
      fill: $booked && $currentDay ? theme.palette.primary?.main : theme.customColors.systemPrimary01
    },
    "a": {
      color: $booked && $currentDay ? theme.palette.primary?.main : theme.customColors.textPrimary
    }
  },

  ".warning": {
    ".icon path":{
      fill: theme.customColors.systemSecondary03
    }
  },

  ".teachers":{
    textTransform:"capitalize"
  },
  

  [theme.breakpoints.down("sm")]:{
    fontSize:"0.875rem",
    letterSpacing: "0.058px",
    padding: theme.spacing(0, 1),
    width:"50%",
    
    ".seats":{
      marginBottom:"0"
    }
  }
}));

export const InfoTypography = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(1.5),

  [theme.breakpoints.down("sm")]:{
    gap: "5px"
  }
}));

export const SeatsTypography = styled(Typography)<
  TypographyProps & { component?: ElementType}
>(() => ({
  fontSize: "1.3125rem"
}));




