/* eslint-disable max-len */

import { getCookieByName, getEnvironmentVariables } from "./general";
import type {  Language } from "../types";

export function persistLanguage (item: Language){
  const { corporateDomain } = getEnvironmentVariables();

  document.cookie = `languageStored=${JSON.stringify(item)};secure; domain=${corporateDomain}; path=/`;
}

export function getLanguage() {
  const itemStored = getCookieByName("languageStored");

  if (itemStored) {
    return JSON.parse(itemStored) as Language;
  } else {
    return { id: "it", label: "Italian" } as Language;
  }
}
