
import {
  Box,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  useRef,
  useState,
  type ChangeEvent,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import {
  CardProfileBox,
  ContentBox,
  Description,
  TitleTypography
} from "./CardProfile.styles";
import { CardProfileProps } from "./CardProfile.types";
import {
  Icon,
  Link,
  Modal,
  Spinner
} from "../../components";
import {
  useCvFormativeQuery,
  useGetProfileImageQuery,
  useMutationProfileImageQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import { getError } from "../../utils/error";
import { ButtonBookmark } from "../Button";

export function CardProfile({
  city,
  email,
  location,
  name,
  phone,
  surname
}: CardProfileProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const authenticationMode = useAuthStore(state => state.mode);
  const userId = useAuthStore(state => state.userData?.id_user);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();
  const [modalText, setModalText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const { data: profileImage } = useGetProfileImageQuery({});

  const { data: cvData } = useCvFormativeQuery({
    studentId: userId?.toString() ?? "0"
  });

  const { 
    mutate: mutateProfileImage,
    error:mutateProfileError, 
    isSuccess:mutateProfileIsSuccess,
    isLoading:mutateProfileIsLoading, 
    isError : mutateProfileIsError
  } = useMutationProfileImageQuery();

  const acceptFileType = "image/*";

  useEffect(()=> {
    if (mutateProfileIsError && mutateProfileError){
      const errorModal = getError(mutateProfileError)?.[0]?.message;
      setShowModal(true);
      if(errorModal){
        const ismodalForbiddenText = isJSON(errorModal) && JSON.parse(errorModal)?.message === "Forbidden";
        if(ismodalForbiddenText){
          setModalText(t("forbidden_image"));
        }
        else{
          setModalText( errorModal?.toString() ?? "generic error");
        }
      }
    
    } else if( mutateProfileIsLoading){
      setSpinnerLoading(true);
    } else {
      setSpinnerLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ 
    mutateProfileIsError,
    mutateProfileError,
    mutateProfileIsSuccess,
    mutateProfileIsLoading
  ]
  );

  return (
    <>
      <CardProfileBox>
        <TitleTypography>
          { t("your_data") }
        </TitleTypography>
        <ContentBox>
          <Box
            borderRadius= "8px"
            height="fit-content"
            lineHeight= "0.1"
            position= "relative"
            width= { isMobile ? "106px" : "30%" }

            sx={ {
              "> :not(button)":{
                borderRadius: "8px",
                objectFit: "cover",
                width: "100%",
                [theme.breakpoints.down("sm")]:{
                  height: "99px",
                  width: "106px"
                }
              }
            } }
          >
            {
              spinnerLoading ? (
                <Spinner/>
              ) : (
                profileImage ? (
                  <img
                    src={ profileImage }
                    alt={ `${file?.name}` }
                    style={ {
                      aspectRatio: "1",
                      backgroundColor: theme.palette.grey?.[400]
                    } }
                  />
                ) : (
                  <Stack
                    justifyContent="center"
                    sx={ {
                      aspectRatio: "1",
                      backgroundColor: theme.palette.grey?.[400]
                    } }
                  >
                    <Typography
                      fontWeight={ 700 }
                      fontSize={ isMobile ? "3rem" : "5rem" }
                      margin={ theme.spacing(0) }
                      color={ theme.customColors.backgroundPrimaryLighter }
                      textAlign="center"
                      textTransform="uppercase"
                      width="100%"
                    >
                      { name?.charAt(0) + surname?.charAt(0) }
                    </Typography>
                  </Stack>
                )
              )
            }
            <ButtonBookmark
              aria-label={ t("upload_file") }
              onClick={ ()=> handleUploadClick() }
              variant="contained"
              sx={ {
                ".icon path":{
                  fill: theme.palette.background?.paper
                },
                borderRadius: theme.spacing(1),
                bottom: theme.spacing(-1),
                boxShadow: "none",
                minWidth: "unset",
                padding: theme.spacing(1),
                position: "absolute",
                right: theme.spacing(-1)
              } }
            >
              <input
                aria-label={ t("upload_file") }
                accept={ acceptFileType }
                className="FileUpload"
                type="file"
                ref={ hiddenFileInput }
                onChange={ handleFileChange }
                style={ { display: "none" } }
              />
              <Icon
                icon="Icons_modifica"
                size="1rem"
              />
            </ButtonBookmark>
          </Box>
          <Description>
            <Typography
              fontSize={ "32px" }
              fontWeight={ 700 }
              lineHeight={ 1.334 }
              margin={ 0 }
              paddingBottom={ "8px" }
              variant="h1"
            >
              { name } { surname }
            </Typography>
            {
              authenticationMode !== "SSO" ? (
                <Link href="/access/password/change">
                  { t("change_password") }
                </Link>
              ) : null
            }
            {
              email ? (
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  { email }
                </Typography>
              ) : null
            }
            {
              phone ? (
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  { phone }
                </Typography>
              ) : null
            }
            {
              (city || location) ? (
                <Box
                  display="flex"
                  lineHeight="normal"
                >
                  {
                    city ? (
                      <Typography
                        fontWeight="700"
                        paddingRight="3px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        { city }
                      </Typography>
                    ) : null
                  }
                  {
                    location ? (
                      <Typography >
                        { location }
                      </Typography>
                    ) : null
                  }
                </Box>
              ) : null
            }
            <Link
              download={ `${name}_${surname}_cv.csv` }
              rel="noreferrer"
              target="_blank"
              href={ URL.createObjectURL(
                new Blob(
                  [cvData ?? "no data"],
                  { type: "text/csv" }
                )
              ) }
              className="button-cv"
              color={ `${theme.customColors.textPrimaryAlternative} !important` }
            >
              { t("go_to_formative_cv") }
            </Link>
          </Description>
        </ContentBox>
      </CardProfileBox>
      <Modal
        open={ showModal }
        onClose={ ()=> {
          setShowModal(false);
          setSpinnerLoading(false);
        } }
        description=""
        title={ modalText }
      />
    </>
  );

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setFile(e.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      const fileContent = e.target.files[0];
      reader.onload = () => {
        const formdata = new FormData();
        if (fileContent){
          if(fileContent.size > 2000000 ){
            setShowModal(true);
            setModalText(t("file_bigger_text") );
          } else {
            formdata.append("file", fileContent, fileContent.name);
            mutateProfileImage(formdata);
          }
        }
      };
    }
  }

  function handleUploadClick() {
    if (hiddenFileInput.current){
      hiddenFileInput.current.click();
    }
    // if (!file) {
    //   return;
    // }
  }

  function isJSON(str:string) {
    try {
      JSON.stringify(JSON.parse(str));
      return true;
    } catch (e) {
      return false;
    }
  }
}
