import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {
  Grid,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  cloneElement,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  StyledTabsFilters,
  StyledTab,
  StyledTabBox,
  StyledTabContent,
  TitleTypography
} from "./TabsFilters.styles";
import type { TabsFiltersProps } from "./TabsFilters.types";
import type { ArrayElement } from "../../types";
import { Icon } from "../Icon";
import { Select } from "../Select/Select.component";

/**
 * this component expects filter and setFilter to be set as state of the parent,
 * where an unfiltered case is represented by the empty string
 * @param param0 
 * @returns 
 */
export function TabsFilters<T extends string>({
  contents,
  count,
  filter,
  options,
  setFilter,
  title,
  ...props
}: TabsFiltersProps<T>) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(`${contents?.[1]?.iconTab}-1`);
  const { t } = useTranslation();

  // NOTE: it would be much easier to just export a type alias from the types file
  const detectIconTab = ({ iconTab }: Pick<ArrayElement<typeof contents>, "iconTab">) => {
    switch (iconTab){
    case "list":
      return "Icons_Listato";
    case "grid":
      return "icons_griglia";
    case "square" :
      return "Icons_Listato_modulo";
    default : return "Icons_Listato_modulo";
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // TO GET Code of Current Product
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  // const courseCode = params?.id || "";

  return (
    <StyledTabsFilters
      className={ "modules-tab"  }
      { ...props }
    >
      <TabContext value={ value }>
        <StyledTabBox>
          <Grid
            display={ "flex" }
            flexDirection={ "column" }
            gap={ 1.75 }
            width={ isMobile ? "100%" : "70%" }>
            <TitleTypography variant="h2">
              { title }
            </TitleTypography>
            {
              count ? (
                <Typography
                  sx={ {
                    [theme.breakpoints.down("sm")]:{
                      fontSize: "0.875rem",
                      fontWeight: "700",
                      lineHeight: "1.28571"
                    }
                  } }>
                  { count + " " + t("favourite_saved") }
                </Typography>
              ) : null
            }
          </Grid>
          <Grid
            display={ "flex" }
            alignItems={ "flex-end" }
          >
            <Grid
              className={ `${filter || open ? "filters selected" : " filters" }` }
              alignItems={ "center" }
              display={ "flex" }
              flexDirection={ "column" }
              marginRight={ "28px" }
              position={ "relative" }
              sx={ {
                ".MuiFormControl-root ":{
                  position:"absolute",
                  top:"-1.875rem"
                },
                [theme.breakpoints.down("sm")]:{
                  marginRight:"7px"
                }
              } }
            >
              <Icon
                color={ open ? theme.palette.primary?.main : theme.customColors.systemPrimary01 }
                icon={ "icons_filtri" }
                onClick={ ()=> {
                  setOpen(!open);}
                }
              />
              <Select
                options={ options }
                id={ t("filter") || undefined }
                open={ open }
                onClose={ () => setOpen(false) }
                onOpen={ () => setOpen(true) }
                onChange={ (changeEvent) => {
                  if (!setFilter) return;
                  if (changeEvent.target.value === filter) {
                    setFilter("" as T);
                  } else {
                    setFilter(changeEvent.target.value as T);
                  }
                } }
                onClick={ (clickEvent) => {
                  if (!setFilter) return;
                  if ((clickEvent.target as HTMLOptionElement).selected === true) {
                    setFilter("" as T);
                  }
                } }
                value={ filter }
              />
              <Typography
                sx={ {
                  [theme.breakpoints.down("sm")]:{
                    fontSize: "0.75rem",
                    fontWeight: "500",
                    lineHeight: "0.875rem"
                  }
                } }
              >
                { t("filter") }
              </Typography>
            </Grid>
            <Grid
              alignItems={ "center" }
              display={ "flex" }
              flexDirection={ "column" }
              sx={ {
                ".MuiTabs-root":{
                  minHeight:"unset"
                }
              } }
            >
              <TabList
                onChange={ (_, newValue) => setValue(newValue) }
                aria-label={ t("filter") }
              >
                {
                  contents.map((item, idx)=> {
                    const icon = detectIconTab({ iconTab: item.iconTab });
                    return (
                      <StyledTab
                        aria-label={ "Change view" }
                        key={ `${item.iconTab}-${idx}` }
                        icon={
                          <Icon
                            color={ theme.customColors.systemPrimary01 }
                            className={ `icon ${item.iconTab}` }
                            icon={ icon }
                            
                          />
                        }
                        value={ `${item.iconTab}-${idx}` }
                      />
                    );
                  })
                }
              </TabList>
              <Typography
                sx={ {
                  [theme.breakpoints.down("sm")]:{
                    fontSize: "0.75rem",
                    fontWeight: "500",
                    lineHeight: "0.875rem"
                  }
                } }
              >{ t("watch") }</Typography>
            </Grid>
          </Grid>
        </StyledTabBox>
        {
          contents.map(({ components , iconTab }, idx)=> (
            <StyledTabContent value={ `${iconTab}-${idx}` } key={ `${iconTab}-${idx}` }>
              {
                components.map((Element, index: number) => {
                  return cloneElement(Element, { key:`itemElement-${index}` });
                })
      
              }
            </StyledTabContent>
          ))
        }
      </TabContext>
    </StyledTabsFilters>
  );
}
