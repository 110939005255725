/* eslint-disable max-len */
import {
  Box,
  BoxProps,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  styled,
  useTheme
} from "@mui/material/styles";
import {
  Fragment,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  BoxCorners,
  Icon,
  GridBox,
  GuardedLink,
  Spinner
} from "../../components";
import { NAVBAR_ITEMS, PERMISSIONS } from "../../consts";
import { useNavigate } from "../../services";
import { useAuthStore, useNavbarStore } from "../../stores";
import { getCookieByName, getEnvironmentVariables, getLastPath } from "../../utils/general";

const { environment } = getEnvironmentVariables();

const DomainBox = styled(Box)<
  BoxProps & { $selected: boolean }
>(({
  $selected,
  theme
}) => ({
  alignItems: "center",
  aspectRatio: "1.4",
  background: `${theme.linearGradients.gradientC}, ${theme.palette.primary?.main}`,
  backgroundSize: "cover",
  borderRadius:theme.spacing(0.2),
  boxShadow: $selected ? `0px 0px 4px 0px ${theme.customColors.accentVariantA}` : undefined,
  cursor:"pointer",
  display: "flex",
  justifyContent: "center",
  outline: $selected
    ? `${theme.spacing(0.25)} solid ${theme.customColors.accentVariantA}`
    : undefined,
  overflow: "hidden",
  padding: theme.spacing(0, 1.25),
  textAlign: "center",
  transition:"all .2s linear"
}));

export function SignupDomain() {
  const { t } = useTranslation();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const authStoreIsLoading = useAuthStore(state => state.isLoading);
  const domains = useAuthStore(state => state.userInfo?.initiatives);
  const hasCorporate = useAuthStore(state => Boolean(state.userData?.initiative_id));
  const isAdmin = useAuthStore(state =>
    state.userData?.permissions?.some(permission => (
      permission === PERMISSIONS["admin.dashboard.access"]
    ))
  );
  const isLoading = useAuthStore(state => state.isLoading);
  const postInitiativeId = useAuthStore(state => state.postInitiativeId);
  const sessionToken = useAuthStore(state => state.sessionToken);
  const signOut = useAuthStore(state => state.signOut);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
  const [goToAdmin, setGoToAdmin] = useState<boolean>(false);
  
  const { adminLink }= getEnvironmentVariables();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const primaryPage =  smartConfiguration && smartConfiguration?.visForyou === false ? 
    NAVBAR_ITEMS.EXPLORE :  NAVBAR_ITEMS.FOR_YOU;

  const authenticationMode = useAuthStore(state => state.mode);

  const isOneInitiative = Array.isArray(domains) && domains.length === 1 && !isAdmin;
  const loadingSpinnerCookie = getCookieByName("loadingSpinner");

  if(!isOneInitiative && !loadingSpinnerCookie){
    setSpinnerLoginVisible(false);
  }
  if(!isOneInitiative){
    document.cookie = `loadingSpinner=false;secure; expires=${new Date().toUTCString()}; domain=.${getLastPath()}; path=/`;

  }
  
  // TODO: QUESTO VA NEL LOADER!!!!
  useEffect(()=> {
    const subDomainRedirect = !!(smartConfiguration?.urlDomain && smartConfiguration?.urlCorporateDomain);
    /* LOADING */
    if (hasCorporate || isLoading) {
      return;
    /* ADMIN NO INITIATIVES */
    } else if (Array.isArray(domains) && domains.length === 0 && isAdmin) {
      window.location.href = `${adminLink}?sessionId=${sessionToken}`;
    /* NO ADMIN SINGEL INITIATIVE */
    } else if (Array.isArray(domains) && domains.length === 1 && !isAdmin && !smartConfiguration) {
      // setSelectedDomain(domains[0].id);
      // handleConfirm();
      postInitiativeId(domains[0].id);
      setNavbarItem(primaryPage);
      setSpinnerLoginVisible(true);
    /* REDIRECT TO INITIATIVE SUB-DOMAIN */
    } else if (
      environment !== "LOCAL"
      && subDomainRedirect
    ) {
      setNavbarItem(primaryPage);
      setSpinnerLoginVisible(false);
    /* SELECT ADMIN OPTION */
    } else if (smartConfiguration && !subDomainRedirect && goToAdmin) {
      window.location.href = `${adminLink}?sessionId=${sessionToken}`;
    /* REDIRECT TO ROOT */
    } else if (smartConfiguration && !subDomainRedirect && !goToAdmin) {
      // navigate("/");
      navigate("/signup");
      setNavbarItem(primaryPage);
    }
  }, [
    adminLink, 
    domains, 
    goToAdmin, 
    hasCorporate, 
    isAdmin, 
    isLoading, 
    navigate, 
    primaryPage, 
    postInitiativeId, 
    setSpinnerLoginVisible,
    sessionToken, 
    setNavbarItem, 
    smartConfiguration, 
    authenticationMode]);

  return isOneInitiative ? 
    <Spinner/> : (
      <>
        <GuardedLink
          alignSelf="flex-start"
          color="info"
          onClick={ () => { signOut() } }
          href={ "/" }
          startIcon={
            <Icon
              icon="arrow_left"
              color={ theme.customColors.systemPrimary01 }
              size={ isMobile ? 15 : 20 }
            />
          }
          variant="text"
          sx={ {
            fontSize: (isMobile ? "0.75rem" : "1.125rem"),
            fontWeight: 400,
            lineHeight: isMobile ? (11 / 6) : (11 / 9),
            minWidth: isMobile ? "auto" : "128px",
            padding: isMobile ? "16px 0 0 0" : undefined
          } }
        >
          { t("back") }
        </GuardedLink>
        <Stack
          alignItems="center"
          gap={ isMobile ? 3 : 6 }
          width={ isMobile ? "100%" : "90%" }
        >
          { !authStoreIsLoading && domains?.length === 0 ? (
            <Typography
              fontSize={ isMobile ? "0.875rem" : "1.25rem" }
              lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
              textAlign="center"
            >
              { isAdmin ? t("redirect_admin") : t("no_initiatives_available") }
            </Typography>
          ) : (
            <Fragment>
              <Stack
                alignItems="center"
                gap={ isMobile ? 1 : 2 }
              >
                <Typography
                  color="primary"
                  fontSize={ isMobile ? "1.25rem" : "2rem" }
                  fontWeight={ 700 }
                  lineHeight={ isMobile ? (21 / 20) : (19 / 16) }
                  textAlign="center"
                >
                  { t("portal_selection") }
                </Typography>
                <Typography
                  fontSize={ isMobile ? "0.875rem" : "1.25rem" }
                  lineHeight={ isMobile ? (9 / 7) : (21 / 20) }
                  textAlign="center"
                >
                  { t("portal_continue") }
                </Typography>
              </Stack>
              {
                
                (hasCorporate && isLoading) ? (
                  <Spinner />
                ) : (
                  <GridBox
                    columns={ 2 }
                    gap = { isMobile ? 1 : 5 }
                    padding={ isMobile ? "12px 16px" : theme.spacing(4, 5) }
                    position="relative"
                    width="100%"
                  >
                    { isAdmin &&
                      <DomainBox
                        key={ "domain--admin" }
                        $selected={ goToAdmin }
                        onClick={ () => {
                          setSelectedDomain(null);
                          setGoToAdmin(true);
                        } }
                      >
                        <Typography
                          fontSize={ isMobile ? "0.875rem" : "2.5rem" }
                          fontWeight={ goToAdmin ? 700 : undefined }
                          textAlign="center"
                          sx={ {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            userSelect: "none"
                          } }
                        >
                          { t("go_to_admin") }
                        </Typography>
                      </DomainBox>
                    }
                    {
                      domains?.map((domain) => (
                        <DomainBox
                          key={ `domain--${domain.id}` }
                          $selected={ domain.id === selectedDomain }
                          onClick={ () => {
                            setSelectedDomain(domain.id);
                            setGoToAdmin(false);
                          } }
                        >
                          <Typography
                            fontSize={ isMobile ? "1rem" : "2rem" }
                            fontWeight={ (domain.id === selectedDomain) ? 700 : undefined }
                            textAlign="center"
                            sx={ {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              transition:"all .2s linear",
                              userSelect: "none"
                            } }
                          >
                            { domain.label }
                          </Typography>
                        </DomainBox>
                      ))
                    }

                    <Fragment>
                      <BoxCorners $isSignup={ true } $cornersPosition="topLeft" $filled={ false }/>
                      <BoxCorners $isSignup={ true } $cornersPosition="bottomRight" $filled={ false } />
                    </Fragment>

                  </GridBox>
                )
              }
              { /* TODO: replace with button or handle href value */ }
              <GuardedLink
                disabled={ !selectedDomain && !goToAdmin }
                // eslint-disable-next-line max-len
                href={ goToAdmin ? `${adminLink}?sessionId=${sessionToken}&mode=${authenticationMode}&initiativeUrl=${window.location.host}` : "/" }
                onClick={ () => handleConfirm() }
                target={  "_self" }
                type={ goToAdmin ? "button" : "submit" }
              >
                { t("continue") }
              </GuardedLink>
            </Fragment>
          )
          }
        </Stack>
      </>
    );

  function handleConfirm() {
    if(!selectedDomain) {
      return goToAdmin ? true : false;
    } else {
      setSpinnerLoginVisible(true);
      postInitiativeId(selectedDomain.toString());
      return false;
    }
  }
}
