
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CardSkeleton, TitleSkeleton, CarouselSkeletonContainer, CardSkeletonTitle, CardSkeletonCategory, CardSkeletonBottom, CardSkeletonBottom2, CardSkeletonBottom1, CardSkeletonTop } from "./CarouselSkeleton.styles";

export function CarouselSkeleton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(theme);
  console.log(isMobile);

  return (
    <>
      <CarouselSkeletonContainer $small={ isMobile }>
        <TitleSkeleton></TitleSkeleton>
        <div className="container">
          <CardSkeleton $small={ isMobile }>
            <CardSkeletonTop></CardSkeletonTop>
            <CardSkeletonCategory></CardSkeletonCategory>
            <CardSkeletonTitle></CardSkeletonTitle>
            <CardSkeletonBottom>
              <CardSkeletonBottom1></CardSkeletonBottom1>
              <CardSkeletonBottom2></CardSkeletonBottom2>
            </CardSkeletonBottom>
          </CardSkeleton>
          <CardSkeleton $small={ isMobile }>
            <CardSkeletonTop></CardSkeletonTop>
            <CardSkeletonCategory></CardSkeletonCategory>
            <CardSkeletonTitle></CardSkeletonTitle>
            <CardSkeletonBottom>
              <CardSkeletonBottom1></CardSkeletonBottom1>
              <CardSkeletonBottom2></CardSkeletonBottom2>
            </CardSkeletonBottom>
          </CardSkeleton>
          <CardSkeleton $small={ isMobile }>
            <CardSkeletonTop></CardSkeletonTop>
            <CardSkeletonCategory></CardSkeletonCategory>
            <CardSkeletonTitle></CardSkeletonTitle>
            <CardSkeletonBottom>
              <CardSkeletonBottom1></CardSkeletonBottom1>
              <CardSkeletonBottom2></CardSkeletonBottom2>
            </CardSkeletonBottom>
          </CardSkeleton>
        </div>
      </CarouselSkeletonContainer>
    </>
  );
}
