import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetcherFactory } from "../services/fetcher";
import { getEnvironmentVariables } from "../utils/general";
const {
  basePath
} = getEnvironmentVariables();

export interface StepResponse {
   action: null
   enabled: boolean
   id: number
   steps:Step[]
}

export interface Step {
    label: string;
    description?: string | null;
    fields: Field[];
    order:number
  }

export interface Field {
    label: string;
    key: string;
    required: boolean;
    type: FieldType;
    order?: number;
    options?: Option[];
    depends_on?: string[];
  }

  type FieldType = "TEXT" | "LIST" | "EMAIL" | "DATE" | "BOOLEAN" | "PARAGRAPH" | "DIVIDER"|"INTEGER";

export interface Option {
    label: string;
    value: string;
  }

  interface OptionResponse{
    key:string,
    options:Option[]
  }

  interface SubmitFormRequest {
    formType: string;
    data: Record<string, unknown>;
  }

export function useSubmitForm() {
  const fetcher = fetcherFactory();

  // Accesso ai token e ad altri dati necessari dallo store di autenticazione
  const { corporateLanguage } = getEnvironmentVariables();

  const queryClient = useQueryClient();

  const { basePath } = getEnvironmentVariables();

  return useMutation({
    mutationFn: ({ formType, data }: SubmitFormRequest) =>
      fetcher(`${basePath}/usermgmt/corporates/${corporateLanguage}/forms/${formType}/submit`, {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST"
      })
        .then((res) => res.json())
        .catch((error) => {
          throw error;
        }),
    onSuccess: (data, { formType }) => {
      // Invalida le query pertinenti per aggiornare i dati dopo la sottomissione del form
      queryClient.invalidateQueries({ queryKey: [`/usermgmt/corporates/${corporateLanguage}/forms/${formType}`] });
    }
  });
}

export const useFormSteps = ({
  enabled = true,
  formType
}: {
  enabled?: boolean;
  formType: string;
}) => {
  const fetcher = fetcherFactory();

  const { corporateLanguage } = getEnvironmentVariables();
  const uri = `${basePath}/usermgmt/corporates/${corporateLanguage}/forms/${formType}`;
  return useQuery<
    unknown,
    unknown,
    StepResponse,
    QueryKey
  >({
    enabled: Boolean(enabled && corporateLanguage && formType),
    queryFn: () => fetcher(`${uri}`,   {
      headers: {
        "accept": "application/json"
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((data: StepResponse) => {
        return data;
      })
      .catch((error) => {
        throw error;
      }),
    queryKey: ["useFormSteps", corporateLanguage, formType]
  });
};


export const useOptionsList = ({
  enabled = true,
  formType,
  dependsOn,
  fieldKey
}: {
    enabled?: boolean;
    formType: string;
    fieldKey?:string;
    dependsOn?: { [key: string]: string }[]
  }) => {
  const fetcher = fetcherFactory();
  const { corporateLanguage } = getEnvironmentVariables();
  const uri = `${basePath}/usermgmt/corporates/${corporateLanguage}/forms/${formType}/fields/${fieldKey}/options?`;
  let queryString = "";

  if (dependsOn) {
    queryString = dependsOn
      .map(item => Object.entries(item).map(([key, value]) => `${key}=${value}`))
      .flat()
      .join("&");
  }
  return useQuery<
      unknown,
      unknown,
      OptionResponse,
      QueryKey
    >({
      enabled: Boolean(enabled && corporateLanguage && formType && fieldKey),
      queryFn: () => fetcher(`${uri}${queryString}`, {
        body: null,
        headers: {
          "Content-Type": "application/json"
        },
        method: "GET"

      })
        .then((res) => {
          return res.json();
        })
        .then((data: OptionResponse) => {
          return data;
        })
        .catch((error) => {
          throw error;
        }),
      queryKey: ["useOptionsList", corporateLanguage, formType, fieldKey,dependsOn]
    });
};
