import { 
  Dialog,
  Grid, 
  Stack, 
  SwipeableDrawer,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  format,
  isSameDay,
  lastDayOfMonth,
  isAfter
} from "date-fns";
import {
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  AgendaStack,
  CalendarBox,
  HeroBox,
  CalendarCarouselBox
} from "./Agenda.styles";
import bg from "../../assets/agendaBg.png";
import {
  // NewsCard,
  // type BadgeProps,
  BigCarouselCard,
  CalendarCard,
  CalendarCarouselCard,
  Carousel,
  CarouselCard,
  GridMain,
  SmartLearningModalDeleteContent,
  SmartLearningModalEditContent,
  WidgetDrawer,
  type DayCourse,
  type DayEvents,
  Spinner
} from "../../components";
import {
  CAROUSEL_TYPE,
  CONTENT_TYPE,
  PAGE_NAME,
  STATUS_LO
} from "../../consts";
import {
  useContentQuery,
  useStructuresQueryAgenda
} from "../../queries";
import { useAuthStore, useCalendarSelectedDateStore } from "../../stores";
import type {
  CalendarDateObject,
  DayToHighlight,
  JavaBasicDate,
  LearningObject,
  MetaData,
  SmartLearningObject
} from "../../types";
import {
  calendarTimeAsDateTime,
  contentIsExpiring
} from "../../utils/calendar";
import {
  stringifyJavaBasicDate,
  toJavaBasicDate
} from "../../utils/datetime";
import { createEndDate, createStartDate } from "../../utils/general";
import { CarouselBox } from "../Explore/Explore.styles";


const contentTypeLabels = {
  ASYNC: "Digital",
  BLENDED: "Blended",
  SMART_LEARNING_SLOT: "Smart Learning",
  SYNC: "Live"
};

export function Agenda() {
  const { t } = useTranslation();
  const theme = useTheme();
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smartLearning = useAuthStore(state => state.smartConfiguration?.smartLearning);
  const widgetCalendar = useAuthStore(state => state.smartConfiguration?.widgetCalendar);
  const [showSmartCarousel, setShowSmartCarousel] = useState(false);
  const smartLearningCarousel = useRef<HTMLDivElement>(null);

  //action states
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState("");
  const [actionDate, setActionDate] = useState(new Date());
  const [actionId, setActionId] = useState(0);
  const [actionStart, setActionStart] = useState("0");
  const [actionEnd, setActionEnd] = useState("0");

  //date states
  const [selectedDate, setSelectedDate] = useState(() => {
    const currentDate = new Date();
    currentDate.setHours(0,0,0);
    return currentDate;
  });
  const [selectedMonth, setSelectedMonth] = useState(parseInt(format(new Date(), "MM")));
  const [selectedYear, setSelectedYear] = useState(parseInt(format(new Date(), "yyyy")));
  const slideToSelectedDate = useCalendarSelectedDateStore((state) => state.slideTo);
  const setCalendarSelectedDate = useCalendarSelectedDateStore((state) =>
    state.setCalendarSelectedDate
  );
 
  //pageStructure
  const { data: pageStructureAgenda } = useStructuresQueryAgenda({
    pageName: PAGE_NAME.AGENDA_OVERVIEW
  });

  //learnerMainLearningObjects
  const { data: main } = useContentQuery<{
    mainLearningObjects: LearningObject[],
    metadata: MetaData
  }>({
    path: pageStructureAgenda?.relativePaths?.[1]?.apiPath
  });

  //learnerExpiringLearningObjects
  const { data: expiring } = useContentQuery<{
    expiringLearningObjects: LearningObject[]
    metadata: MetaData
  }>({
    path: pageStructureAgenda?.relativePaths?.[2]?.apiPath
  });

  //learnersNextLearningObjects
  const { data: next } = useContentQuery<{
    nextLearningObjects: LearningObject[]
    metadata: MetaData
  }>({
    path: pageStructureAgenda?.relativePaths?.[3]?.apiPath
  });

  //learnerAgenda
  const endDate = format(lastDayOfMonth(new Date(selectedYear, selectedMonth - 1, 1)), "yyyy/MM/dd");
  const selected = format(new Date(selectedDate), "yyyy/MM/dd");
  const startDate = format(new Date(selectedYear, selectedMonth -1, 1), "yyyy/MM/dd");
  const {
    data: learnerAgendaDays,
    isInitialLoading: learnerAgendaDaysAreLoading
  } = useContentQuery<{
    daysToHighlight: DayToHighlight[],
    learningObjects: LearningObject[],
    smartLearningSlots: SmartLearningObject[],
    metadata: MetaData
  }>({
    path: pageStructureAgenda?.relativePaths?.[0]?.apiPath,
    queryKey: [selected, startDate, endDate],

    // additional params
    endDate: endDate.replaceAll("/","-"),
    selectedDate: selected.replaceAll("/","-"),
    startDate: startDate.replaceAll("/","-")
  });

  const dateNumbersToHighlight = learnerAgendaDays?.daysToHighlight
    // .filter((dayToHighlight) => (
    //   smartLearning === true
    //   || (dayToHighlight.learningObjects && dayToHighlight.learningObjects.length > 0)
    // ))
    // .map((dayToHighlight) => dayToHighlight.day) ?? [];
    .flatMap((dayToHighlight) => {
      const hasLearningObject = dayToHighlight.learningObjects
        ? dayToHighlight.learningObjects.length > 0
        : false;
      if (smartLearning === true || hasLearningObject) {
        return [dayToHighlight.day];
      } else {
        return [];
      }
    }) ?? [];

  const learningObjects: CalendarDateObject[] =
    learnerAgendaDays?.daysToHighlight
      ?.flatMap((dayToHighlight) => (
        dayToHighlight.learningObjects
          ?.map((learningObject) => ({
            date: [selectedYear, selectedMonth, dayToHighlight.day] as JavaBasicDate,
            dateObjectType: "learningObject" as const,
            endTime: learningObject.endTime, //"3 : 0"
            id: learningObject.id,
            learningObject,
            // learningObject: {
            //   ...learningObject,
            //   empty: false
            // },
            learningObjectType: learningObject.learningObjectType,
            smartLearning: null,
            startTime: learningObject.startTime, //"2 : 0"
            title: learningObject.title
          } satisfies CalendarDateObject)
          ) ?? []
      )) ?? [];

  const smartLearnings: CalendarDateObject[] = smartLearning ? (
    learnerAgendaDays?.daysToHighlight
      ?.flatMap((dayToHighlight) => (
        dayToHighlight?.smartLearningSlots
          ?.map((smartLearning) => ({
            date: [selectedYear, selectedMonth, dayToHighlight.day] as JavaBasicDate,
            dateObjectType: "smartLearning" as const,
            endTime: smartLearning.endTime, //"3 : 0"
            id: smartLearning.id,
            learningObject: null,
            learningObjectType: CONTENT_TYPE.SMART_LEARNING_SLOT,
            smartLearning,
            startTime: smartLearning.startTime, //"2 : 0"
            title: contentTypeLabels[CONTENT_TYPE.SMART_LEARNING_SLOT]
          } satisfies CalendarDateObject)
          ) ?? []
      )) ?? []
  ) : [];

  const dateObjects =
      learningObjects?.concat(smartLearnings)
        .sort((dateObject1, dateObject2) => {
          if (
            dateObject1.dateObjectType === "learningObject"
            && dateObject2.dateObjectType === "smartLearning"
          ) {
            return -1;
          } else if (
            dateObject1.dateObjectType === "smartLearning"
            && dateObject2.dateObjectType === "learningObject"
          ) {
            return 1;
          } else if (
            dateObject1.dateObjectType === "learningObject"
            && dateObject2.dateObjectType === "learningObject"
          ) {
            const courseAIsExpiring = contentIsExpiring(dateObject1.learningObject, selectedDate);
            const courseBIsExpiring = contentIsExpiring(dateObject2.learningObject, selectedDate);
  
            if (courseAIsExpiring && !courseBIsExpiring) {
              return -1;
            } else if (!courseAIsExpiring && courseBIsExpiring) {
              return 1;
            } else { // same value
              return 0;
            }
          } else if (
            dateObject1.dateObjectType === "smartLearning"
            && dateObject2.dateObjectType === "smartLearning"
          ) {
            if (dateObject1.smartLearning.startTime < dateObject2.smartLearning.startTime) {
              return -1;
            } else if (dateObject1.smartLearning.startTime > dateObject1.smartLearning.startTime) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        });

  const carouselDateObjects = dateObjects.filter((dateObject) => {
    const dateObjectDate = new Date(dateObject.date.join("/"));
    return isSameDay(dateObjectDate, selectedDate) || isAfter(dateObjectDate, selectedDate);
  });

  const isFound = carouselDateObjects.some((cardHighlight) => {
    const cardDate = new Date(cardHighlight.date.join("/"));
    return isSameDay(cardDate, selectedDate);
  });

  if (!learnerAgendaDaysAreLoading && !isFound) {
    carouselDateObjects.unshift({
      date: toJavaBasicDate(selectedDate),
      dateObjectType: "empty" as const,
      endTime: null,
      id: null,
      learningObject: null,
      learningObjectType: null,
      smartLearning: null,
      startTime: null,
      title: null
    });
  }

  carouselDateObjects.sort((cdo1, cdo2) => {
    return stringifyJavaBasicDate(cdo1.date) >= stringifyJavaBasicDate(cdo2.date) ? 1 : -1;
  });

  const firstSmartCourse = smartLearnings.find((smartLearning) => (
    smartLearning.dateObjectType !== "empty"
      && !isAfter(
        new Date(),
        calendarTimeAsDateTime({
          date: smartLearning.date,
          time: smartLearning.startTime
        })
      )
  ));//?.id as number | undefined;
  const firstSmartCourseEndTime =
    firstSmartCourse
      ? calendarTimeAsDateTime({
        date: firstSmartCourse?.date,
        time: firstSmartCourse?.endTime
      })
      : null;
  const firtstSmartCourseStartTime =
    firstSmartCourse
      ? calendarTimeAsDateTime({
        date: firstSmartCourse?.date,
        time: firstSmartCourse?.startTime
      })
      : null;

  const calendarCarouselCards = getCalendarCards({ events: carouselDateObjects });

  //learnersSmartLearningObjects
  const { 
    data: smart,
    isLoading: smartIsLoading
  } = useContentQuery<{
      smartLearningObjects: LearningObject[]
      metadata: MetaData
    }>({
      path: pageStructureAgenda?.relativePaths?.[4]?.apiPath,
  
      endTime: format(new Date(firstSmartCourseEndTime as Date),  "yyyy-MM-dd'T'hh:mm:ss.sss'Z'"),
      startTime: format(new Date(firtstSmartCourseStartTime as Date), "yyyy-MM-dd'T'hh:mm:ss.sss'Z'")
    });

  useEffect(() => {
    const slideToIndex = calendarCarouselCards
      .findIndex((calendarCarouselCard) => (
        calendarCarouselCard.find((event) => {
          const eventDate = new Date(event.date.join("/"));
          return isSameDay(eventDate, selectedDate);
        })
      ));
    const slideTo = slideToIndex !== -1
      ? slideToIndex
      : slideToSelectedDate;
    setCalendarSelectedDate({ selectedDate, slideTo });
  }, [
    selectedDate,
    setCalendarSelectedDate,
    slideToSelectedDate,
    calendarCarouselCards
  ]);
  
  useEffect(() => {
    if (!smartIsLoading && showSmartCarousel) {
      scrollToSmartLearning();
    }
  }, [showSmartCarousel, smartIsLoading]);

  useEffect(() => {
    setShowSmartCarousel(false);
  }, [selectedDate]);

  return widgetCalendar === true ? (
    <AgendaStack src={ bg }>
      <GridMain>
        <WidgetDrawer/>
        <Grid
          container
          spacing={ 2 }
        >
          <Grid
            item
            lg={ 6 }
            md={ 5 }
            xs={ 12 }
          >
            <CalendarBox>
              <CalendarCard
                dateObjects={ dateObjects }
                dateNumbersToHighlight={ dateNumbersToHighlight ?? [] }
                selectedExternal={ selectedDate ?? new Date() }
                setSelectedExternal={ setSelectedDate }
                setSelectedMonth={ setSelectedMonth }
                setSelectedYear={ setSelectedYear }

              />
            </CalendarBox>
          </Grid>
          <Grid
            item
            lg={ 6 }
            md={ 7 }
            xs={ 12 }
          >
            <CalendarCarouselBox height="100%">
              {
                (
                  learnerAgendaDays === undefined
                  || learnerAgendaDaysAreLoading
                )
                  ? (
                    <Stack
                      justifyContent="center"
                      sx={ {
                        background: theme.linearGradients.gradientB,
                        borderRadius: theme.spacing(1),
                        height: "100%"
                      } }
                    >
                      <Spinner />
                    </Stack>
                  ) : (
                    <Carousel
                      carouselType={ CAROUSEL_TYPE.DEFAULT }
                      label={ "calendar" }
                      isCalendar={ true }
                    >
                      { calendarCarouselCards.map((calendarCarouselCard, idx) => (
                        <CalendarCarouselCard
                          key={ `calendar-carousel-card-${idx}` }
                          events={ calendarCarouselCard }
                          firstSmartCourseId={ firstSmartCourse?.id ?? undefined }
                          selectAction={ selectAction }
                          scrollAction={ () => {
                            if(!showSmartCarousel) {
                              setShowSmartCarousel(true);
                            } else {
                              scrollToSmartLearning();
                            }
                          } }
                        
                        />
                      )) }
                    </Carousel>
                  )
              }
            </CalendarCarouselBox>
          </Grid>
        </Grid>
        {
          (
            main
            && Array.isArray(main.mainLearningObjects)
            && main?.mainLearningObjects.length > 0
          )
            ? (
              <HeroBox>
                <Carousel
                  carouselType={ CAROUSEL_TYPE.HERO }
                  label="hero-explorePage"
                >
                  { main.mainLearningObjects.map((card, index) => (
                    <BigCarouselCard
                      key={ `explore-hero--card-${index}` }
                      coverPublicURL={ card.coverPublicURL }
                      duration={ card.duration }
                      editionNumber={ card?.editionNumber }
                      endDate={ createEndDate(card) }
                      enrollType={ card.enrollType }
                      id={ card.id }
                      isHighLightEdition={ card?.agendaHighlightEditions?.[0]?.isHighlightEdition ?? false }
                      isToj={ card.isToj }
                      learningObjectType={ card.learningObjectType }
                      learningObjectTypology={ card.learningObjectTypology }
                      notice={ card.availablePlaces?.toString() }
                      percentageOfCompletion={ card.percentageOfCompletion }
                      shortDescription={ card.shortDescription }
                      simple={ true }
                      startDate={ createStartDate(card)  }
                      status={ card.status }
                      title={ card.title }
                    />
                  ))
                  }
                </Carousel>
              </HeroBox>
            ) : null
        }
        {
          (
            expiring
            && Array.isArray(expiring.expiringLearningObjects)
            && expiring.expiringLearningObjects.length > 0
          )
            ? (
              <CarouselBox sx={ { marginTop: "5rem" } }>
                <Carousel
                  carouselType={ CAROUSEL_TYPE.ZOOM }
                  id="agenda-zoom-1"
                  label={ pageStructureAgenda?.relativePaths?.[2]?.title ?? "In scadenza" }
                  title={ `${t("expiring")}` }
                  itemsLength={ expiring.metadata.totalElements }
                >
                  {
                    expiring?.expiringLearningObjects.map((expiringLO, index) => {
                      return <CarouselCard

                        booked={ expiringLO.status !== STATUS_LO.N && expiringLO.status !== STATUS_LO.T }
                        categories={ expiringLO.topicTags || [] }
                        coverPublicURL={ expiringLO.coverPublicURL?.toString() || "" }
                        coverVideoPublicURL={ expiringLO.coverVideoPublicUrl }
                        disabled={ false }
                        duration={ expiringLO.duration }
                        ecm_specialization={ expiringLO.ecm_specialization }
                        ecmRegistration={ expiringLO.ecmRegistration }
                        editionNumber={ expiringLO?.editionNumber }
                        endDate={ createEndDate(expiringLO) }
                        enrollType={ expiringLO.enrollType }
                        expirationDate={ expiringLO.expirationDate }
                        id={ expiringLO.id }
                        isAutoSubscribeEnable={ expiringLO.isAutoSubscribeEnable }
                        isFavourite={ expiringLO.isFavourite }
                        isMandatory={ expiringLO.isMandatory }
                        isSurvey={ expiringLO.isSurvey }
                        isTest={ expiringLO.isTest }
                        isToj={ expiringLO.isToj }
                        key={ `expiring-card-${index}` }
                        learningObjectType={ expiringLO.learningObjectType }
                        learningObjectTypology={ expiringLO.learningObjectTypology }
                        percentageOfCompletion={ expiringLO.percentageOfCompletion }
                        shortDescription={ expiringLO.shortDescription }
                        startDate={ createEndDate(expiringLO)  }
                        status={ expiringLO.status }
                        title={ expiringLO.title }
                      />;
                    })
                  }
                </Carousel>
              </CarouselBox>
            ) : null
        }
        {
          (
            next
            && Array.isArray(next.nextLearningObjects)
            && next.nextLearningObjects.length > 0
          )
            ? (
              <CarouselBox sx={ { marginTop: "5rem" } }>
                <Carousel
                  carouselType={ CAROUSEL_TYPE.ZOOM }
                  id="agenda-zoom-2"
                  title={ `${t("upcoming_events")}` }
                  label={ pageStructureAgenda?.relativePaths?.[3]?.title ?? "Prossimi eventi" }
                  itemsLength={ next.metadata.totalElements }
                >
                  {
                    next?.nextLearningObjects.map((card, index) => {
                      return <CarouselCard
                        booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                        categories={ card.topicTags }
                        coverPublicURL={ card.coverPublicURL?.toString() || "" }
                        coverVideoPublicURL={ card.videoPublicURL }
                        disabled={ false }
                        duration={ card.duration }
                        ecm_specialization={ card.ecm_specialization }
                        ecmRegistration={ card.ecmRegistration }
                        editionNumber={ card?.editionNumber }
                        endDate={ createEndDate(card) }
                        enrollType={ card.enrollType }
                        expirationDate={ card.expirationDate }
                        id={ card.id }
                        isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                        isFavourite={ card.isFavourite }
                        isMandatory={ card.isMandatory }
                        isSurvey={ card.isSurvey }
                        isTest={ card.isTest }
                        isToj={ card.isToj }
                        key={ `expiring-card-${index}` }
                        learningObjectType={ card.learningObjectType }
                        learningObjectTypology={ card.learningObjectTypology }
                        percentageOfCompletion={ card.percentageOfCompletion }
                        shortDescription={ card.shortDescription }
                        startDate={ createStartDate(card)  }
                        status={ card.status }
                        title={ card.title }
                      />;
                    })
                  }
                </Carousel>
              </CarouselBox>
            ) : null
        }
        {
          (
            smart
            && Array.isArray(smart.smartLearningObjects)
            && smart.smartLearningObjects.length > 0
          )
            ? (
              <CarouselBox 
                sx={ { marginTop: "5rem" } }
                ref={ smartLearningCarousel }
                hidden={ !showSmartCarousel }
              >
                <Carousel
                  carouselType={ CAROUSEL_TYPE.ZOOM }
                  id="agenda-zoom-2"
                  title={ `${t("productive_time")}` }
                  label={ pageStructureAgenda?.relativePaths?.[4]?.title ?? "Tempo produttivo" }
                  // eslint-disable-next-line max-len
                  href={ `/smart-learning?endTime=${firstSmartCourseEndTime?.toISOString()}&startTime=${firtstSmartCourseStartTime?.toISOString()}` }
                  itemsLength={ smart.metadata.totalElements }
                >
                  {
                    smart?.smartLearningObjects.map((card, index) => {
                      return <CarouselCard
                        booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                        categories={ card.topicTags }
                        coverPublicURL={ card.coverPublicURL?.toString() || "" }
                        coverVideoPublicURL={ card.videoPublicURL }
                        disabled={ false }
                        duration={ card.duration }
                        ecm_specialization={ card.ecm_specialization }
                        ecmRegistration={ card.ecmRegistration }
                        editionNumber={ card?.editionNumber }
                        endDate={ createStartDate(card) }
                        enrollType={ card.enrollType }
                        expirationDate={ card.expirationDate }
                        id={ card.id }
                        isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                        isFavourite={ card.isFavourite }
                        isMandatory={ card.isMandatory }
                        isSurvey={ card.isSurvey }
                        isTest={ card.isTest }
                        isToj={ card.isToj }
                        key={ `smart-card-${index}` }
                        learningObjectType={ card.learningObjectType }
                        learningObjectTypology={ card.learningObjectTypology }
                        percentageOfCompletion={ card.percentageOfCompletion }
                        shortDescription={ card.shortDescription }
                        startDate={ createStartDate(card)  }
                        status={ card.status }
                        title={ card.title }
                      />;
                    })
                  }
                </Carousel>
              </CarouselBox>
            ) : null
        }
      </GridMain>
      { action.length > 0 &&
        <>
          { isMobile ? 
            <SwipeableDrawer
              anchor={ "bottom" }
              open={ isOpen }
              onClose={ () => setIsOpen(false) }
              onOpen={ () => setIsOpen(true) }
              className={ "smart-drawer" }
              sx={ {
                "& .MuiModal-backdrop": {
                  bottom: "60px"
                },

                "& .MuiPaper-root" : {
                  border: `1px solid ${theme.customColors.borderAccent}`,
                  borderColor: theme.customColors.borderAccent,
                  borderRadius: "8px 8px 0 0",
                  bottom: "60px",
                  boxShadow: `0 0 4px 0 ${theme.customColors.borderAccent}`,
                  padding: "16px 20px",
                  transition: "none",

                  [theme.breakpoints.down("sm")]:{
                    height: "calc(100% - 120px)"
                  }
                }
              } }
            >
              { action == "modify" ? 
                <SmartLearningModalEditContent
                  onClose={ () => setIsOpen(false) }
                  selectedDate={ actionDate }
                  slotId={ actionId }
                  start={ actionStart }
                  end={ actionEnd }
                /> :
                <SmartLearningModalDeleteContent
                  onClose={ () => setIsOpen(false) }
                  slotId={ actionId }
                />
              }
            </SwipeableDrawer> :
            <Dialog
              maxWidth={ false }
              open={ isOpen }
              onClose={ (event, reason) => {
                if(reason == "backdropClick" || reason == "escapeKeyDown") {
                  return;
                }
                setIsOpen(false);
              } }
              scroll={ "paper" }
              sx={ {
                ".MuiDialog-paper ": {
                  background: theme.linearGradients.gradientB,
                  borderRadius:"4px",
                  bottom: "10%",
                  //maxHeight:"calc(100% - 32px)",
                  padding: "24px 40px",
                  width: "494px"
                }
              } }
            >
              { action == "modify" ? 
                <SmartLearningModalEditContent
                  onClose={ () => setIsOpen(false) }
                  selectedDate={ actionDate }
                  slotId={ actionId }
                  start={ actionStart }
                  end={ actionEnd }
                /> :
                <SmartLearningModalDeleteContent
                  onClose={ () => setIsOpen(false) }
                  slotId={ actionId }
                />
              }
            </Dialog> 
          }
        </>
      }
    </AgendaStack>
  ) : (
    <GridMain
      justifyContent={ "center" }
    >
      <Typography 
        padding={ "42px 80px" }
        textAlign={ "center" }
      >
        { t("no_agenda_available") }
      </Typography>
    </GridMain>
  );

  function scrollToSmartLearning() {
    const offsetTop = smartLearningCarousel?.current?.offsetTop ?? 1000;
    const scrollY = offsetTop - 80 - 42; //to make up for the carousel's title height + margin
    document.scrollingElement?.scrollTo({ behavior: "smooth", top: scrollY });
  }

  function getCalendarCards({
    events
  } : {
    events: CalendarDateObject[]
  }) {
    const eventPages: DayEvents[][] = [];
    for (let i = 0; i < events.length; i = i + 3) {
      let eventsToShow = events
        .slice(i, (i + 3))
        .map((event) => {
          const courseObj: DayCourse =
            event.dateObjectType === "learningObject" ? {
              date: event.date,
              dayCourseType: event.dateObjectType,
              duration: event.learningObject.duration ? parseInt(event.learningObject.duration) : 0,
              endTime: event.endTime,
              enrollType: event.learningObject.enrollType,
              expirationDate: event.learningObject.expirationDate,
              id: event.id,
              isMandatory: event.learningObject.isMandatory,
              learningObjectType: event.learningObjectType,
              learningObjectTypology: event.learningObject.learningObjectTypology,
              percentageOfCompletion: event.learningObject.percentageOfCompletion ?? "0%",
              startTime: event.startTime,
              status: event.learningObject.status,
              title: event.title
            } : event.dateObjectType === "smartLearning" ? {
              date: event.date,
              dayCourseType: event.dateObjectType,
              endTime: event.endTime,
              enrollType: null,
              expirationDate: null,
              id: event.id,
              isMandatory: null,
              learningObjectType: event.learningObjectType,
              learningObjectTypology: null,
              percentageOfCompletion: null,
              startTime: event.startTime,
              status: null,
              title: null
            } : {
              date: event.date,
              dayCourseType: event.dateObjectType
            };
          return courseObj;
        });

      const currentPage: DayEvents[] = [];
      eventsToShow.forEach((eventToShow) => {
        if (eventToShow.dayCourseType === "empty") {
          currentPage.push({
            courses: [],
            date: eventToShow?.date
          });
        } else if (eventToShow.learningObjectType === CONTENT_TYPE.SMART_LEARNING_SLOT) {
          currentPage.push({
            courses: [eventToShow],
            date: eventToShow?.date
          });
        } else {
          const existingEventDate = currentPage
            .find((event) => (
              event?.date.join("/") === eventToShow?.date.join("/")
            ));
              
          if (existingEventDate) {
            existingEventDate.courses.push(eventToShow);
          } else {
            currentPage.push({
              courses: [eventToShow],
              date: eventToShow?.date
            });
          }
        }
      });
      eventPages.push(currentPage);
    }
    return eventPages;
  }

  function selectAction(
    action: string, 
    date: Date,
    slotId: number,
    start: string,
    end: string
  ) {
    setAction(action);
    setIsOpen(true);
    setActionDate(date);
    setActionId(slotId);
    setActionStart(start);
    setActionEnd(end);
  }
}
