import { Box, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TabsContentProps } from "./TabsContent.types";
import {
  ButtonLink,
  Carousel,
  CarouselCard
} from "../../components";
import {
  CAROUSEL_TYPE,
  LEVEL,
  STATUS_LO
} from "../../consts";
import { createEndDate, createStartDate } from "../../utils/general";

export function TabsContentActivities({
  contentActivities,
  courseId,
  currentLO,
  rootId,
  setOpenModalEcm,
  title
} :
  & Pick<
    TabsContentProps,
    | "currentLO"
    | "courseId"
    | "rootId"
    >
  & Required<
    Pick<
    TabsContentProps,
    | "contentActivities"
    | "title"
    | "currentLO"
  > & {
    setOpenModalEcm:Dispatch<SetStateAction<boolean>> | undefined
  }
>
) {
  const { state: routerState } = useLocation();
  const navigate = useNavigate();
  const minActivities  = 4;
  const showCta = (contentActivities.length > minActivities);
  const theme = useTheme();


  return (
    <>
      {
        showCta &&
          <Box 
            sx={ {
              display:"flex",
              justifyContent: "flex-end",
              width: "100%"
            } }
          >
            <ButtonLink 
              sx={ {
                cursor: "pointer",
                fontSize: "1.125rem",
                fontWeight: "500",
                minWidth: "unset",
                padding: "0 0 1rem",
                textDecoration: "underline",
                whiteSpace: "nowrap",
                zIndex: "1000",
             
                "&:hover": {
                  background:"none",
                  boxShadow:"none"
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize:"0.75rem"
                },

                "&.Mui-disabled": {
                  color:theme.customColors.backgroundDisabled,
                  textDecoration:"none"
                }
              } }
              onClick={ ()=>{navigate("/activitiesDetail", 
                { state:{ 
                  ...routerState,
                  areCardsEnabled:Boolean(currentLO?.ecm_specialization && !currentLO.ecmRegistration),
                  contentActivities: contentActivities,
                  courseId: routerState?.courseId ?? currentLO.id,
                  learningObjectType: currentLO.learningObjectType,
                  learningObjectTypology: currentLO.learningObjectTypology,
                  rootId:routerState?.rootId ? routerState?.rootId : 
                    routerState?.loRootId ? routerState?.loRootId : 
                      currentLO.learningObjectTypology === LEVEL.PATH ? currentLO.id : null,
                  title: title
                } 
                });
              } }
            >
              Vedi tutti
            </ButtonLink>
          </Box>
      }
      <Carousel
        id={ "tabs-content-activities" }
        carouselType={ CAROUSEL_TYPE.ZOOM_RELATED }
        label={ "tabs-content-activities" }
      >
        {
          contentActivities?.map((card, index) => {
            return  (
              <CarouselCard
                key={ `tabs-content-activity-${index}` }
                booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                categories={ card.topicTags || [] }
                courseId={ card.courseId ?? courseId }
                coverPublicURL={ card?.coverPublicURL }
                coverVideoPublicURL={ card.coverVideoPublicURL }
                disabled={ !card.isEnable || Boolean(currentLO?.ecm_specialization && !currentLO.ecmRegistration) }
                duration={ card.duration  } // TODO: api adapter should do this seconds
                ecmRegistration={ currentLO.ecmRegistration }
                endDate={ createEndDate(card) }
                enrollType={ card.enrollType }
                expirationDate={  card.expirationDate } //{ card.expirationDate }
                ecm_specialization={ currentLO.ecm_specialization }
                id={ card.id }
                isAutoSubscribeEnable={ true } //{ card.isAutoSubscribeEnable }
                isFavourite={ card.isFavourite }
                isSurvey={ card.isSurvey }
                isTest={ card.isTest }
                learningObjectType={ card.learningObjectType }
                learningObjectTypology={ card.learningObjectTypology }
                percentageOfCompletion={ card.percentageOfCompletion }
                rootId={ rootId }
                setOpenModalEcm={ setOpenModalEcm }
                startDate={ createStartDate(card) }
                shortDescription={ card.shortDescription }
                small={ true }
                isToj={ false }
                status={ card.status || "N" }
                title={ card.title }
              />
            );
          })
        }
      </Carousel>
    </>
    
  );
}
